<template>
  <UikitDialog
    :model-value="modelValue"
    full-height
    full-height-no-scroll
    :with-mobile-menu="!isDesktop"
    :no-shadow="!isDesktop"
    :hide-backdrop="!isDesktop"
    close-others-on-mobile
    no-padding
    position="right"
    content-class="osk-header-notification-dialog"
    @update:model-value="emits('update:modelValue', $event)"
  >
    <div class="osk-header-notification-dialog__wrapper">
      <UikitTabs
        v-model="activeTab"
        class="osk-header-notification-dialog__tabs"
      >
        <UikitTabsTab
          :name="NotificationTab.Notification"
          :title="$t('components.notifications.notificationsDialog.notifications')"
          class="osk-header-notification-dialog__tab"
        />
        <UikitTabsTab
          :name="NotificationTab.Comment"
          :title="$t('components.notifications.notificationsDialog.comments')"
          class="osk-header-notification-dialog__tab"
        />
      </UikitTabs>

      <div
        ref="$content"
        class="osk-header-notification-dialog__content"
      >
        <UikitLoaderInner v-if="loading && currentPage === 1" />

        <template v-else>
          <div
            v-if="isEmpty"
            class="osk-header-notification-dialog__empty"
          >
            <span class="osk-header-notification-dialog__empty-title osk-desktop-hidden">
              {{ $t('components.notifications.notificationsDialog.emptyTitle') }}
            </span>

            <template v-if="isNotificationTab">
              {{ $t('components.notifications.notificationsDialog.emptyNotifications') }}
            </template>

            <template v-else>
              {{ $t('components.notifications.notificationsDialog.emptyComments') }}
            </template>
          </div>

          <template v-else>
            <template v-if="data?.needAction?.length">
              <div class="osk-header-notification-dialog__title">
                {{ $t('components.notifications.notificationsDialog.needActions') }}
              </div>

              <ul class="osk-header-notification-dialog__list osk-header-notification-dialog__list-first">
                <li
                  v-for="item in data?.needAction"
                  :key="item.id"
                >
                  <NotificationsItem
                    :item="item"
                    @close="emits('update:modelValue', false)"
                  />
                </li>
              </ul>
            </template>

            <template v-if="data?.earlier?.length">
              <div
                v-if="data?.needAction?.length"
                class="osk-header-notification-dialog__title osk-header-notification-dialog__title_second"
              >
                {{ $t('components.notifications.notificationsDialog.earlier') }}
              </div>

              <ul class="osk-header-notification-dialog__list">
                <li
                  v-for="item in data?.earlier"
                  :key="item.id"
                >
                  <NotificationsItem
                    :item="item"
                    @click="emits('update:modelValue', false)"
                    @close="emits('update:modelValue', false)"
                  />
                </li>
              </ul>
            </template>

            <div
              v-if="currentPage < (totalPages ?? 1) || loading"
              class="osk-header-notification-dialog__bottom"
            >
              <UikitLoaderInner small />
            </div>
          </template>
        </template>
      </div>
    </div>
  </UikitDialog>
</template>

<script setup lang="ts">
import { NotificationTab } from '~/types/components/tabs';

const props = defineProps<{
  modelValue: boolean
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
}>();

const $content = ref();

const { isDesktop } = useUiBreakpoints();
const {
  activeTab,
  isNotificationTab,
  loading,
  currentPage,
  totalPages,
  data,
  isEmpty,
  fetchData,
  removeData,
} = useNotificationsOrComments();

useInfiniteScroll(
  $content,
  async () => {
    if (loading.value || currentPage.value >= (totalPages.value ?? 1)) return;

    await fetchData(true);

    // TODO after vueuse 10.* there is a bug
    $content.value.scrollBy(0, 1);
  },
  { distance: 10, interval: 1000 },
);

watch(activeTab, () => {
  removeData();
  fetchData();
});

watch(() => props.modelValue, (value) => {
  if (value) {
    fetchData();
    return;
  }

  removeData();
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-header-notification-dialog {
  @include font-body-all;

  @include media-query(lg-and-up) {
    width: auto !important;
  }

  .osk-dialog__close {
    display: none;

    @include media-query(lg-and-up) {
      right: 50px;
      display: block;
    }
  }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include media-query(lg-and-up) {
      padding-top: 89px;
      width: 504px;
    }
  }

  &__tabs {
    justify-content: center;

    @include media-query(lg-and-up) {
      margin: 0 52px;
    }

    .osk-tab__title {
      @include active-bold;
      padding: $indent-compact 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      margin: 0 42px 1px;

      @include media-query(lg-and-up) {
        padding: $indent-compact 33px;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        margin-bottom: 1px;
        font-weight: 700;
      }
    }

    .osk-tab_active {
      .osk-tab__title {
        font-weight: 700;
      }
    }

    .osk-tab_active .osk-tab__title:after {
      @include media-query(lg-and-down) {
        width: 76%;
      }
    }
  }

  &__content {
    height: 100%;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    @include media-query(lg-and-up) {
      padding: 31px 52px 0;
      display: block;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    color: $grey;
    margin: auto 0;

    @include media-query(lg-and-up) {
      color: $black;
      padding-top: 27px;
      margin: 0;
    }
  }

  &__empty-title {
    @include font-style($font-size-heading, $line-height-heading, $font-weight-bold);
    color: $black;
    margin-bottom: 14px;
  }

  &__title {
    @include font-style($font-size-heading, $line-height-heading, $font-weight-bold);
    margin-bottom: $indent-medium;
    margin-top: 25px;

    &_second {
      margin-top: 0;
    }

    @include media-query(lg-and-up) {
      margin-top: 0;
    }
  }

  &__list {
    width: 100%;
  }

  &__list-first {
    margin-bottom: 34px;

    @include media-query(lg-and-up) {
      margin-bottom: 39px;
    }

    & + .osk-header-notification-dialog__bottom {
      margin-top: -34px;

      @include media-query(lg-and-up) {
        margin-top: -39px;
      }
    }
  }

  &__bottom {
    flex-shrink: 0;
    height: 40px;
    position: relative;
  }
}
</style>
