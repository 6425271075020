<template>
  <div class="osk-header-search-results">
    <div
      v-if="headerSearchQuery.length < 3 && isShowHistory"
      class="osk-header-search-results__history"
    >
      <div class="osk-header-search-results__history-title">
        {{ $t('components.header.headerSearchResults.title') }}
      </div>

      <CustomScrollbar
        class="osk-header-search-results__scrollbar"
        :style="{ maxHeight: isDesktop ? '248px' : '100%' }"
      >
        <ul
          :class="{
            'osk-header-search-results__result-list': true,
            'osk-header-search-results__result-list_withPadding': isPadding,
          }"
        >
          <li
            v-for="(item, index) in historyProducts"
            :key="`product-${index}`"
            class="osk-header-search-results__result-product"
            @click="historyProductClick(item)"
          >
            <span class="osk-header-search-results__result-product-title">
              {{ item.search_value }}
              <template v-if="item.attributes?.length">
                (<template
                  v-for="(attr, index) in item.attributes"
                  :key="index"
                >
                  {{ attr?.values?.name }}<template v-if="index !== item.attributes.length - 1">, </template>
                </template>)
              </template>
            </span>

            <UikitIcon
              name="ArrowBack"
              size="s"
              class="osk-header-search-results__result-icon"
            />
          </li>

          <li
            v-for="(item, index) in historyUsers"
            :key="`user-${index}`"
            class="osk-header-search-results__result-user"
            @click="historyUserClick(item)"
          >
            <div class="osk-header-search-results__result-user-content">
              <span>
                @{{ item.nickname }}
              </span>

              <span class="osk-header-search-results__result-user-counter">
                {{ $t('components.header.headerSearchResults.productsTotal', item.products_total) }}
              </span>
            </div>

            <UikitIcon
              name="ArrowBack"
              size="s"
              class="osk-header-search-results__result-icon"
            />
          </li>
        </ul>
      </CustomScrollbar>
    </div>

    <div
      v-if="isShowResults"
      class="osk-header-search-results__results"
    >
      <UikitTabs
        v-model="activeTab"
        class="osk-header-search-results__tabs"
        small
      >
        <UikitTabsTab
          v-for="(item) in productCategories"
          :key="item.id"
          :name="item.id"
          :title="$t(item.name)"
          class="osk-header-search-results__tab"
        />
      </UikitTabs>

      <div class="osk-header-search-results__result-content">
        <UikitLoaderInner
          v-if="isResultLoading"
          class="osk-header-search-results__loader"
          small
        />

        <template v-else-if="productsData || usersData">
          <CustomScrollbar
            v-if="isResults"
            class="osk-header-search-results__scrollbar"
            :style="{ maxHeight: isDesktop ? '248px' : '100%' }"
          >
            <ul class="osk-header-search-results__result-list">
              <li
                v-for="(item, index) in resultProducts"
                :key="`product-${index}`"
                class="osk-header-search-results__result-product"
                @click="productClick(item, index)"
              >
                <span class="osk-header-search-results__result-product-title">
                  {{ item.search_value }}
                  <template v-if="item.attributes?.length">
                    (<template
                      v-for="(attr, index) in item.attributes"
                      :key="index"
                    >
                      {{ attr?.values?.name }}<template v-if="index !== item.attributes.length - 1">, </template>
                    </template>)
                  </template>
                </span>

                <span class="osk-header-search-results__result-product-counter">
                  {{ item.products_total }}
                </span>

                <UikitIcon
                  name="ArrowBack"
                  size="s"
                  class="osk-header-search-results__result-icon"
                />
              </li>

              <li
                v-for="(item, index) in resultUsers"
                :key="`user-${index}`"
                class="osk-header-search-results__result-user"
                @click="userClick(item, index)"
              >
                <div class="osk-header-search-results__result-user-content">
                  <span>
                    @{{ item.nickname }}
                  </span>

                  <span class="osk-header-search-results__result-user-counter">
                    {{ $t('components.header.headerSearchResults.productsTotal', item.products_total) }}
                  </span>
                </div>

                <UikitIcon
                  name="ArrowBack"
                  size="s"
                  class="osk-header-search-results__result-icon"
                />
              </li>
            </ul>
          </CustomScrollbar>

          <div
            v-else
            class="osk-header-search-results__empty"
          >
            <template v-if="isDesktop">
              {{ $t('components.header.headerSearchResults.empty') }}
            </template>

            <template v-else>
              <div class="osk-header-search-results__empty-title">
                {{ $t('components.header.headerSearchResults.empty.mobile.title') }}
              </div>

              <div class="osk-header-search-results__empty-text">
                {{ $t('components.header.headerSearchResults.empty.mobile.text') }}
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { productCategories } from '~/providers/search';
import {
  UserSearchItem,
  ProductSearchItem,
} from '~/types/components/search';

import { AnalyticsEvents } from '~/types/analytics';

const emits = defineEmits<{
  (e: 'clickLink'): void,
  (e: 'clickLink'): void,
}>();

const {
  headerSearchQuery,
  activeTab,
  productsData,
  usersData,
  historyProducts,
  historyUsers,
  isShowHistory,
  resultProducts,
  resultUsers,
  isResultLoading,
  isResults,
  goToCatalog,
  handleProductClick,
  goToUser,
  handleUserClick,
  isHeaderSearchFull,
  showResults,
  isHeaderSearchMobileDialog,
  isShowResults,
  searchId,
  globalSearchId,
  usageSearchId,
  isGoToNextPageFromSearch,
} = useHeaderSearch();
const { preventBodyScroll } = usePreventScroll();
const { activeMenuCategory } = useActiveMenuCategory();
const { isDesktop } = useUiBreakpoints();

const { $addEvent } = useNuxtApp();

const activeTabCategoryId = computed(() => productCategories.find((item) => item.id === activeTab.value)?.categoryId);

const isPadding = computed(() => {
  if (!historyProducts.value && !historyUsers.value) return false;

  const sum = (historyProducts.value?.length ?? 0) + (historyUsers.value?.length ?? 0);

  return sum < 5;
});

function handleItemClick(item: 'item' | 'user', historySearchValue?: string) {
  isGoToNextPageFromSearch.value = true;
  usageSearchId.value = globalSearchId.value;

  emits('clickLink');

  $addEvent(AnalyticsEvents.ClickEvent, {
    page: 'search',
    value: historySearchValue ?? headerSearchQuery.value,
    id_item: globalSearchId.value,
    item,
    action: 'suggest',
    chapter: historySearchValue ? 'search _history' : undefined,
  });
}

function historyProductClick(item: ProductSearchItem) {
  handleItemClick('item', item.search_value);
  goToCatalog(item);
}

function historyUserClick(item: UserSearchItem) {
  handleItemClick('user', item.nickname);
  goToUser(item);
}

function productClick(item: ProductSearchItem, index: number) {
  handleItemClick('item');
  handleProductClick(item, index);
}

function userClick(item: UserSearchItem, index: number) {
  handleItemClick('user');
  handleUserClick(item, index);
}

watch(isHeaderSearchFull, (value) => {
  if (!isDesktop.value) {
    return;
  }

  if (value) {
    if (activeMenuCategory.value === 'men') {
      activeTab.value = 'man';
    } else if (activeMenuCategory.value === 'women') {
      activeTab.value = 'woman';
    } else {
      activeTab.value = activeMenuCategory.value ?? 'woman';
    }

    showResults();
  }
}, { immediate: true });

watchDebounced(headerSearchQuery, async (value) => {
  productsData.value = null;
  usersData.value = null;

  if (value && (isHeaderSearchFull.value || isHeaderSearchMobileDialog.value)) {
    await showResults();
  }

  if (!value) {
    searchId.value = null;
    return;
  }

  if (isResults.value) {
    $addEvent(AnalyticsEvents.ClickEvent, {
      page: 'search',
      value,
      id_item: globalSearchId.value,
    });
  } else {
    $addEvent(AnalyticsEvents.BlockView, {
      page: 'search',
      value,
      id_item: globalSearchId.value,
      action: 'zero_result',
    });
  }
}, { debounce: 300 });

watchDebounced(activeTab, async (value) => {
  await showResults(true);

  $addEvent(AnalyticsEvents.ClickEvent, {
    page: 'search',
    value: headerSearchQuery.value,
    id_item: globalSearchId.value,
    chapter: value,
    id_chapter: String(activeTabCategoryId.value),
  });
}, { debounce: 300 });

watch(isDesktop, (value) => {
  if (!value) {
    preventBodyScroll(false);
    isHeaderSearchFull.value = false;
  }
});

onUnmounted(() => {
  searchId.value = null;

  if (!isGoToNextPageFromSearch.value) {
    usageSearchId.value = null;
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

$search-width: 576px;

.osk-header-search-results {
  &__scrollbar {
    margin: 0 -16px;
    padding: 0 16px;

    @include media-query(lg-and-up) {
      margin: 0;
      padding: 0;
    }
  }

  .os-scrollbar {
    --os-handle-min-size: 150px;
    --os-handle-perpendicular-size: 2px;
    --os-handle-bg: rgba(0, 0, 0, .6)
  }

  .os-scrollbar-horizontal {
    display: none;
  }

  &__results {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include media-query(lg-and-up) {
      width: $search-width;
      margin: 0 auto -52px;
      padding-top: $indent-medium;
    }
  }

  &__history {
    width: 100%;
    height: 100%;
    margin-top: 10px;

    @include media-query(lg-and-up) {
      width: $search-width;
      margin: 0 auto -52px;
      padding: 28px 0 0 $indent-compact;
    }

    .osk-header-search-results__result-list {
      padding-top: 6px;

      @include media-query(lg-and-up) {
        padding-top: 0;
      }

      &_withPadding {
        @include media-query(lg-and-up) {
          padding-bottom: 16px;
        }
      }
    }

    .osk-header-search-results__result-icon {
      margin-left: auto;
    }
  }

  &__history-title {
    @include font-style(18px, 22px, $font-weight-bold);
    margin-bottom: $indent-compact;
    padding-top: $indent-compact;

    @include media-query(lg-and-up) {
      @include font-style($font-size-body, $line-height-body, $font-weight-bold);
      padding-top: 0;
    }
  }

  &__result-content {
    position: relative;
    min-height: 65px;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include media-query(lg-and-up) {
      height: auto;
      display: block;
      padding-left: 12px;
    }
  }

  &__empty {
    margin: auto 0;

    @include media-query(lg-and-up) {
      padding: $indent-medium;
      margin: 0;
      margin-bottom: $indent-medium;
    }
  }

  &__empty-title {
    @include font-style($font-size-heading, $line-height-heading, $font-weight-bold);
    margin-bottom: 14px;
  }

  &__empty-text {
    @include font-style($font-size-body, 18px, $font-weight-medium);
    color: $grey;
  }

  &__result-list {
    height: 100%;
    scrollbar-width: none;
    padding-top: $indent-large;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-query(lg-and-up) {
      padding-right: 148px;
      max-height: 248px;
      height: auto;
      padding-top: 0;
    }
  }

  &__result-product {
    @include font-style($font-size-body, $line-height-body, $font-weight-medium);
    display: flex;
    align-items: center;
    padding: $indent-medium 0;
    cursor: pointer;
  }

  &__result-product-title {
    padding-right: $indent-medium;
  }

  &__result-product-counter {
    @include font-style($font-size-caption, $line-height-caption, $font-weight-semibold);
    margin-left: auto;
    margin-right: $indent-small;
    padding: $indent-xsmall $indent-small;
    background: $grey-4;
    border-radius: 100px;
  }

  &__result-icon {
    transform: rotate(180deg);
    flex-shrink: 0;
  }

  &__result-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 0;
    cursor: pointer;
  }

  &__result-user-content {
    display: flex;
    flex-direction: column;
  }

  &__result-user-counter {
    font-weight: $font-weight-medium;
    font-size: 10px;
    line-height: 12px;
    margin-top: $indent-xsmall;
    color: $grey;
  }

  &__tabs {
    margin-bottom: 1px;
    background-color: #fff;
    position: relative;
    z-index: 1;
  }

  &__tab {
    @include media-query(lg-and-up) {
      .osk-tab__title {
        padding: $indent-compact $indent-mmedium;
      }
    }
  }
}
</style>
