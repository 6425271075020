<template>
  <span
    class="osk-header-notification"
    @click="handleNotification"
  >
    <div
      v-if="count"
      class="osk-header-notification__count"
    >
      {{ count > 99 ? '99+' : count }}
    </div>

    <UikitIcon
      v-if="count"
      name="NotificationFilled"
      size="l"
    />

    <UikitIcon
      v-else
      name="Notification"
      size="l"
    />
  </span>
</template>

<script lang="ts" setup>
import { AnalyticsEvents } from '~/types/analytics';

const { $addEvent } = useNuxtApp();
const { isAuth, openAuthDialog } = useAccountStore();

const { count } = useNotificationsStore();
const { isNotificationDialog } = useHeader();

function handleNotification(e: Event) {
  $addEvent(AnalyticsEvents.HeaderLinkClick, {
    item: 'notification',
  });

  if (!isAuth.value) {
    openAuthDialog({ chapter: 'notification' });
  } else {
    isNotificationDialog.value = true;
  }
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-header-notification {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__count {
    margin-right: $indent-xsmall;
  }
}
</style>
