<template>
  <UikitForm
    @submit-validate-success="submitSuccess"
  >
    <UikitFormInput
      name="email"
      :placeholder="$t('components.auth.signInForm.email')"
      border-bottom
      rules="required|email"
    />

    <UikitFormInput
      name="password"
      type="password"
      :placeholder="$t('components.auth.signInForm.password')"
      border-bottom
      rules="required"
    />

    <UikitFormErrorMessage name="globalError" />

    <a
      href="/forgot"
      class="osk-sign-in-form__link"
      target="_blank"
      @click="handleForgot"
    >
      {{ $t('components.auth.signInForm.forgot') }}
    </a>

    <UikitButton
      class="osk-sign-in-form__button"
      full-width
      :loading="loading"
    >
      {{ $t('components.auth.signInForm.enter') }}
    </UikitButton>
  </UikitForm>
</template>

<script setup lang="ts">
import type { TSubmitSuccess } from '~/types/components/form';
import { useAccountService } from '~/providers/account';
import { AnalyticsEvents } from '~/types/analytics';

interface LoginForm {
  email: string;
  password: string;
  globalError?: string
}

const props = defineProps<{
  page?: string
}>();

const emits = defineEmits<{
  (e: 'success'): void,
}>();

const { auth } = useAccountService();
const { getGlobalSettings } = useGlobalSettings();
const { $addEvent } = useNuxtApp();
const { t } = useI18n();

const loading = ref(false);

async function submitSuccess({ values, actions }: TSubmitSuccess<LoginForm>) {
  actions.setFieldError('globalError', undefined);
  loading.value = true;

  try {
    await auth(values.email, values.password);
    await getGlobalSettings();

    emits('success');

    $addEvent(AnalyticsEvents.EnterLogin, {
      page: props.page,
    });
  } catch (error) {
    actions.setFieldError('globalError', t('components.auth.signInForm.error'));
    loading.value = false;
    console.error(error);
  }
}

function handleForgot() {
  $addEvent(AnalyticsEvents.DialogLoginRemember, {
    page: props.page,
  });
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-sign-in-form {
  &__button {
    margin-top: $indent-xlarge;
  }

  &__link {
    @include font-style($font-size-body, $line-height-body, $font-weight-semibold);
    color: $grey;
    text-decoration: underline;
    font-family: $font-family-default;
    display: inline-block;
    margin-top: $indent-compact;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
