import { ICategoryMenu } from '~/types/menu';

export const lifestyleEN: ICategoryMenu[] = [
  {
    title: 'New Arrivals',
    href: '/catalog/lifestyle',
    items: [
      {
        title: 'New Arrivals',
        href: '/catalog/lifestyle',
        items: [
          {
            title: 'HOME',
            href: '/catalog/lifestyle/home',
          },
          {
            title: 'StarGift',
            href: '/catalog/lifestyle/stargift',
          },
          {
            title: 'Devices',
            href: '/catalog/lifestyle/devices',
          },
        ],
      },
    ],
  },
  {
    title: 'Brands',
    href: '/brands/lifestyle',
    items: [
      {
        title: 'Brands',
        items: [
          {
            title: 'Hermes',
            href: '/catalog/hermes/lifestyle',
          },
          {
            title: 'Dyson',
            href: '/catalog/dyson/lifestyle',
          },
          {
            title: 'Kaws',
            href: '/catalog/kaws/lifestyle',
          },
          {
            title: 'Baccarat',
            href: '/catalog/baccarat/lifestyle',
          },
          {
            title: 'Daum',
            href: '/catalog/daum/lifestyle',
          },
          {
            title: 'Tiffany&Co',
            href: '/catalog/tiffanyco/lifestyle',
          },
          {
            title: 'Louis Vuitton',
            href: '/catalog/louis-vuitton/lifestyle',
          },
          {
            title: 'Versace',
            href: '/catalog/versace/lifestyle',
          },
        ],
      },
      {
        href: '/brands/lifestyle',
        items: [
          {
            title: 'Cartier',
            href: '/catalog/cartier/lifestyle',
          },
          {
            title: 'Gucci',
            href: '/catalog/gucci/lifestyle',
          },
          {
            title: 'Saint Louis',
            href: '/catalog/saintlouis/lifestyle',
          },
          {
            title: 'Lalique',
            href: '/catalog/lalique/lifestyle',
          },
          {
            title: 'Faberge',
            href: '/catalog/faberge/lifestyle',
          },
          {
            title: 'Villeroy&Boch',
            href: '/catalog/villeroyboch/lifestyle',
          },
        ],
      },
    ],
  },
  {
    title: 'Home',
    href: '/catalog/lifestyle/home',
    items: [
      {
        title: 'Home',
        href: '/catalog/lifestyle/home',
        items: [
          {
            title: 'Tableware',
            href: '/catalog/lifestyle/home/posuda',
          },
          {
            title: 'Interior Items',
            href: '/catalog/lifestyle/home/predmety-interiyera',
          },
          {
            title: 'Textile',
            href: '/catalog/lifestyle/home/tekstil',
          },
          {
            title: 'Flavors',
            href: '/catalog/lifestyle/home/aromaty',
          },
          {
            title: 'Books',
            href: '/catalog/lifestyle/home/knigi',
          },
        ],
      },
    ],
  },
  {
    title: 'StarGift',
    href: '/catalog/lifestyle/stargift',
    items: [
      {
        title: 'StarGift',
        items: [
          {
            title: 'History',
            href: '/catalog/lifestyle/stargift/istoriya',
          },
          {
            title: 'Movie',
            href: '/catalog/lifestyle/stargift/kino',
          },
          {
            title: 'Music',
            href: '/catalog/lifestyle/stargift/muzyka',
          },
          {
            title: 'Sport',
            href: '/catalog/lifestyle/stargift/sport',
          },
          {
            title: 'Personal Addresses',
            href: '/catalog/lifestyle/stargift/imennyye-obrashcheniya',
          },
          {
            title: 'Exclusive',
            href: '/catalog/lifestyle/stargift/eksklyuziv',
          },

        ],
      },
      {
        href: '/catalog/lifestyle/stargift',
        items: [
          {
            title: 'Meetings',
            href: '/catalog/lifestyle/stargift/vstrechi',
          },
          {
            title: 'New Products',
            href: '/catalog/lifestyle/stargift/novinki',
          },
          {
            title: 'Literature',
            href: '/catalog/lifestyle/stargift/literatura',
          },
          {
            title: 'Politics',
            href: '/catalog/lifestyle/stargift/politika',
          },
          {
            title: 'Art',
            href: '/catalog/lifestyle/stargift/iskusstvo',
          },
        ],
      },
    ],
  },
  {
    title: 'Devices',
    href: '/catalog/lifestyle/devices',
  },
];
