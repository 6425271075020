<template>
  <UikitDialog
    :model-value="modelValue"
    position="right"
    full-height
    full-height-no-scroll
    :no-shadow="!isDesktop"
    :hide-backdrop="!isDesktop"
    close-others-on-mobile
    no-padding
    content-class="osk-auth-old-dialog"
    @update:model-value="emits('update:modelValue', $event)"
  >
    <UikitTabs
      v-model="activeTab"
      class="osk-auth-old-dialog__tabs"
      :class="{'osk-auth-old-dialog__tabs-signup': activeTab === 'sign-up'}"
    >
      <UikitTabsTab
        name="sign-in"
        title="Войти"
        class="osk-auth-old-dialog__tab"
      />
      <UikitTabsTab
        name="sign-up"
        title="Регистрация"
        class="osk-auth-old-dialog__tab"
      />
    </UikitTabs>

    <AuthSignInForm
      v-if="activeTab === 'sign-in'"
      class="osk-auth-old-dialog__form"
      :page="page"
      @success="emits('success')"
    />

    <AuthSignUpForm
      v-if="activeTab === 'sign-up'"
      class="osk-auth-old-dialog__form osk-auth-old-dialog__form-signup"
      :page="page"
      @success="emits('success')"
    />
  </UikitDialog>
</template>

<script setup lang="ts">
import { AnalyticsEvents } from '~/types/analytics';

const props = defineProps<{
  modelValue: boolean
  page?: string
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
  (e: 'success'): void,
}>();

const { isDesktop } = useUiBreakpoints();
const { $addEvent } = useNuxtApp();

const activeTab = ref('sign-in');

watch(() => props.modelValue, (value) => {
  if (value) {
    $addEvent(AnalyticsEvents.CartLoginView, {
      page: props.page,
    });
  }
});

watch(() => activeTab.value, (value) => {
  if (value === 'sign-in') {
    $addEvent(AnalyticsEvents.DialogLoginTab, {
      page: props.page,
    });
  } else if (value === 'sign-up') {
    $addEvent(AnalyticsEvents.DialogSignUpTab, {
      page: props.page,
    });
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-auth-old-dialog {
  &.osk-dialog__body {
    @include font-body-all;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    justify-content: center;
    font-family: $font-family-default;
    padding: 0 16px;

    @media screen and (max-height: 600px) {
      justify-content: flex-start;
      padding-top: 54px;
      padding-bottom: 16px;
    }

    @include media-query(lg-and-up) {
      width: 504px;
      padding: 0 52px;

      @media screen and (max-height: 600px) {
        justify-content: center;
        padding-top: 0;
        padding-bottom: 0;
      }

      @media screen and (max-height: 640px) {
        justify-content: flex-start;
        padding-top: 94px;
        padding-bottom: 54px;
      }
    }
  }

  .osk-dialog__close {
    right: auto;
    left: 12px;
    top: 16px;

    @include media-query(lg-and-up) {
      right: auto;
      left: 48px;
      top: 44px;
    }
  }

  &__tabs {
    margin-bottom: $indent-mlarge;
  }

  &__tab {
    width: 50%;
    display: flex;
    justify-content: center;

    @include media-query(md-and-up) {
      display: block;
    }
  }

  .osk-dialog__close {
    @include media-query(lg-and-up) {
      top: 44px;
      right: 44px;
    }
  }
}
</style>
