import { ICategoryMenu } from '~/types/menu';

export const kidsEN: ICategoryMenu[] = [
  {
    title: 'OSKELLY Favorites',
    href: '/catalog/detskoe?oskellyChoice=true',
    items: [
      {
        title: 'Girls 0-3',
        href: '/catalog/detskoe/devochki-0-3?oskellyChoice=true',
        items: [
          {
            title: 'Shoes',
            href: '/catalog/detskoe/devochki-0-3/obuv?oskellyChoice=true',
          },
          {
            title: 'Clothes',
            href: '/catalog/detskoe/devochki-0-3/odezhda?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Girls 4-14',
        href: '/catalog/detskoe/devochki-4-14?oskellyChoice=true',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/detskoe/devochki-4-14/aksessuary?oskellyChoice=true',
          },
          {
            title: 'Shoes',
            href: '/catalog/detskoe/devochki-4-14/obuv?oskellyChoice=true',
          },
          {
            title: 'Clothing',
            href: '/catalog/detskoe/devochki-4-14/odezhda?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Boys 0-3',
        href: '/catalog/detskoe/malchiki-0-3?oskellyChoice=true',
        items: [
          {
            title: 'Shoes',
            href: '/catalog/detskoe/malchiki-0-3/obuv?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Boys 4-14',
        href: '/catalog/detskoe/malchiki-4-14?oskellyChoice=true',
        items: [
          {
            title: 'Shoes',
            href: '/catalog/detskoe/malchiki-4-14/obuv?oskellyChoice=true',
          },
        ],
      },
    ],
  },
  {
    title: 'New Arrivals',
    href: '/catalog/detskoe',
    items: [
      {
        title: 'Girls 0-3',
        href: '/catalog/detskoe/devochki-0-3',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/detskoe/devochki-0-3/aksessuary',
          },
          {
            title: 'Shoes',
            href: '/catalog/detskoe/devochki-0-3/obuv',
          },
          {
            title: 'Clothes',
            href: '/catalog/detskoe/devochki-0-3/odezhda',
          },
        ],
      },
      {
        title: 'Girls 4-14',
        href: '/catalog/detskoe/devochki-4-14',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/detskoe/devochki-4-14/aksessuary',
          },
          {
            title: 'Shoes',
            href: '/catalog/detskoe/devochki-4-14/obuv',
          },
          {
            title: 'Clothing',
            href: '/catalog/detskoe/devochki-4-14/odezhda',
          },
          {
            title: 'Bags',
            href: '/catalog/detskoe/devochki-4-14/sumki',
          },
        ],
      },
      {
        title: 'Boys 0-3',
        href: '/catalog/detskoe/malchiki-0-3',
        items: [
          {
            title: 'Accessories and Gifts',
            href: '/catalog/detskoe/malchiki-0-3/aksessuary-i-podarky',
          },
          {
            title: 'Shoes',
            href: '/catalog/detskoe/malchiki-0-3/obuv',
          },
          {
            title: 'Clothes',
            href: '/catalog/detskoe/malchiki-0-3/odezhda',
          },
        ],
      },
      {
        title: 'Boys 4-14',
        href: '/catalog/detskoe/malchiki-4-14',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/detskoe/malchiki-4-14/aksessuary',
          },
          {
            title: 'Shoes',
            href: '/catalog/detskoe/malchiki-4-14/obuv',
          },
          {
            title: 'Clothing',
            href: '/catalog/detskoe/malchiki-4-14/odezhda',
          },
          {
            title: 'Bags',
            href: '/catalog/detskoe/malchiki-4-14/sumki',
          },
        ],
      },
    ],
  },
  {
    title: 'Brands',
    href: '/brands/detskoe',
    items: [
      {
        title: 'Brands',
        items: [
          {
            title: 'ARMANI JUNIOR',
            href: '/catalog/armani-junior/detskoe',
          },
          {
            title: 'BONPOINT',
            href: '/catalog/bonpoint/detskoe',
          },
          {
            title: 'BURBERRY',
            href: '/catalog/burberry/detskoe',
          },
          {
            title: 'DOLCE & GABBANA KIDS',
            href: '/catalog/dolce--gabbana-kids/detskoe',
          },
          {
            title: 'DOLCE & GABBANA',
            href: '/catalog/dolcegabbana/detskoe',
          },
          {
            title: 'GUCCI',
            href: '/catalog/gucci/detskoe',
          },
          {
            title: 'GUCCI KIDS',
            href: '/catalog/guccikids/detskoe',
          },
          {
            title: 'IL GUFO',
            href: '/catalog/ilgufo/detskoe',
          },
        ],
      },
      {
        href: '/brands/detskoe',
        items: [
          {
            title: 'MISS BLUMARINE',
            href: '/catalog/miss-blumarine/detskoe',
          },
          {
            title: 'MONCLER',
            href: '/catalog/moncler/detskoe',
          },
          {
            title: 'MONNALISA',
            href: '/catalog/monnalisa/detskoe',
          },
          {
            title: 'POLO RALPH LAUREN',
            href: '/catalog/poloralphlauren/detskoe',
          },
          {
            title: 'RALPH LAUREN',
            href: '/catalog/ralphlauren/detskoe',
          },
          {
            title: 'ZHANNA & ANNA',
            href: '/catalog/zhannaanna/detskoe',
          },
        ],
      },
    ],
  },
  {
    title: 'Girls 0-3',
    href: '/catalog/detskoe/devochki-0-3',
    items: [
      {
        title: 'Girls 0-3',
        href: '/catalog/detskoe/devochki-0-3',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/detskoe/devochki-0-3/aksessuary',
          },
          {
            title: 'Shoes',
            href: '/catalog/detskoe/devochki-0-3/obuv',
          },
          {
            title: 'Clothes',
            href: '/catalog/detskoe/devochki-0-3/odezhda',
          },
        ],
      },
    ],
  },
  {
    title: 'Girls 4-14',
    href: '/catalog/detskoe/devochki-4-14',
    items: [
      {
        title: 'Girls 4-14',
        href: '/catalog/detskoe/devochki-4-14',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/detskoe/devochki-4-14/aksessuary',
          },
          {
            title: 'Shoes',
            href: '/catalog/detskoe/devochki-4-14/obuv',
          },
          {
            title: 'Clothing',
            href: '/catalog/detskoe/devochki-4-14/odezhda',
          },
          {
            title: 'Bags',
            href: '/catalog/detskoe/devochki-4-14/sumki',
          },
        ],
      },
    ],
  },
  {
    title: 'Boys 0-3',
    href: '/catalog/detskoe/malchiki-0-3',
    items: [
      {
        title: 'Boys 0-3',
        href: '/catalog/detskoe/malchiki-0-3',
        items: [
          {
            title: 'Accessories and Gifts',
            href: '/catalog/detskoe/malchiki-0-3/aksessuary-i-podarky',
          },
          {
            title: 'Shoes',
            href: '/catalog/detskoe/malchiki-0-3/obuv',
          },
          {
            title: 'Clothes',
            href: '/catalog/detskoe/malchiki-0-3/odezhda',
          },
        ],
      },
    ],
  },
  {
    title: 'Boys 4-14',
    href: '/catalog/detskoe/malchiki-4-14',
    items: [
      {
        title: 'Boys 4-14',
        href: '/catalog/detskoe/malchiki-4-14',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/detskoe/malchiki-4-14/aksessuary',
          },
          {
            title: 'Shoes',
            href: '/catalog/detskoe/malchiki-4-14/obuv',
          },
          {
            title: 'Clothing',
            href: '/catalog/detskoe/malchiki-4-14/odezhda',
          },
          {
            title: 'Bags',
            href: '/catalog/detskoe/malchiki-4-14/sumki',
          },
        ],
      },
    ],
  },
];
