import { ICategoryMenu } from '~/types/menu';

export const menEN: ICategoryMenu[] = [
  {
    title: 'OSKELLY Favorites',
    href: '/catalog/muzhskoe?oskellyChoice=true',
    items: [
      {
        title: 'Accessories',
        href: '/catalog/muzhskoe/aksessuary?oskellyChoice=true',
        items: [
          {
            title: 'Hats',
            href: '/catalog/muzhskoe/aksessuary/golovnye-ubory?oskellyChoice=true',
          },
          {
            title: 'Wallets and Cardholders',
            href: '/catalog/muzhskoe/aksessuary/koshelki-i-kardholdery?oskellyChoice=true',
          },
          {
            title: 'Glasses',
            href: '/catalog/muzhskoe/aksessuary/ochki?oskellyChoice=true',
          },
          {
            title: 'Belts',
            href: '/catalog/muzhskoe/aksessuary/remni?oskellyChoice=true',
          },
          {
            title: 'Jewelry',
            href: '/catalog/muzhskoe/aksessuary/ukrasheniya?oskellyChoice=true',
          },
          {
            title: 'Watches',
            href: '/catalog/muzhskoe/aksessuary/chasy?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Beauty',
        href: '/catalog/muzhskoe/beauty?oskellyChoice=true',
      },
      {
        title: 'Shoes',
        href: '/catalog/muzhskoe/obuv?oskellyChoice=true',
        items: [
          {
            title: 'Boots',
            href: '/catalog/muzhskoe/obuv/botinki?oskellyChoice=true',
          },
          {
            title: 'Sneakers',
            href: '/catalog/muzhskoe/obuv/krossovki-i-kedy?oskellyChoice=true',
          },
          {
            title: 'Loafers and Moccasins',
            href: '/catalog/muzhskoe/obuv/lofery-i-mokasiny?oskellyChoice=true',
          },
          {
            title: 'Sandals and Flip flops',
            href: '/catalog/muzhskoe/obuv/sandalii-i-shlepanci?oskellyChoice=true',
          },
          {
            title: 'Flats',
            href: '/catalog/muzhskoe/obuv/tufli?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Clothing',
        href: '/catalog/muzhskoe/odezhda?oskellyChoice=true',
        items: [
          {
            title: 'Trousers and Shorts',
            href: '/catalog/muzhskoe/odezhda/bryuki-i-shorty?oskellyChoice=true',
          },
          {
            title: 'Outerwear',
            href: '/catalog/muzhskoe/odezhda/verhnyaya-odezhda?oskellyChoice=true',
          },
          {
            title: 'Suits',
            href: '/catalog/muzhskoe/odezhda/kostyumy?oskellyChoice=true',
          },
          {
            title: 'T-shirts and Singlets',
            href: '/catalog/muzhskoe/odezhda/majki-i-futbolki?oskellyChoice=true',
          },
          {
            title: 'Jackets',
            href: '/catalog/muzhskoe/odezhda/pidzhaki?oskellyChoice=true',
          },
          {
            title: 'Shirts',
            href: '/catalog/muzhskoe/odezhda/rubashki?oskellyChoice=true',
          },
          {
            title: 'Hoodies and Sweatshirts',
            href: '/catalog/muzhskoe/odezhda/hudi-i-tolstovki?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Bags',
        href: '/catalog/muzhskoe/sumki?oskellyChoice=true',
        items: [
          {
            title: 'Briefcases',
            href: '/catalog/muzhskoe/sumki/portfeli?oskellyChoice=true',
          },
          {
            title: 'Shoulder Bags',
            href: '/catalog/muzhskoe/sumki/sumki-na-plecho?oskellyChoice=true',
          },
        ],
      },
    ],
  },
  {
    title: 'New Arrivals',
    href: '/catalog/muzhskoe',
    items: [
      {
        title: 'Accessories',
        href: '/catalog/muzhskoe/aksessuary',
        items: [
          {
            title: 'Smartphone Accessories',
            href: '/catalog/muzhskoe/aksessuary/aksessuary-dlya-smartfona',
          },
          {
            title: 'Keyrings',
            href: '/catalog/muzhskoe/aksessuary/breloki',
          },
          {
            title: 'Business Card Holders',
            href: '/catalog/muzhskoe/aksessuary/vizitnicy',
          },
          {
            title: 'Ties and Bow ties',
            href: '/catalog/muzhskoe/aksessuary/galstuki-i-babochki',
          },
          {
            title: 'Hats',
            href: '/catalog/muzhskoe/aksessuary/golovnye-ubory',
          },
          {
            title: 'Key Cases',
            href: '/catalog/muzhskoe/aksessuary/klyuchnitsy',
          },
          {
            title: 'Wallets and Cardholders',
            href: '/catalog/muzhskoe/aksessuary/koshelki-i-kardholdery',
          },
        ],
      },
      {
        title: 'Beauty',
        href: '/catalog/muzhskoe/beauty',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/muzhskoe/beauty/aksessuary',
          },
          {
            title: 'Barbershop',
            href: '/catalog/muzhskoe/beauty/barbershop',
          },
          {
            title: 'Deodorants',
            href: '/catalog/muzhskoe/beauty/dezodoranty',
          },
          {
            title: 'Fragrance',
            href: '/catalog/muzhskoe/beauty/parfyumeriya',
          },
        ],
      },
      {
        title: 'Shoes',
        href: '/catalog/muzhskoe/obuv',
        items: [
          {
            title: 'Boots',
            href: '/catalog/muzhskoe/obuv/botinki',
          },
          {
            title: 'Sneakers',
            href: '/catalog/muzhskoe/obuv/krossovki-i-kedy',
          },
          {
            title: 'Loafers and Moccasins',
            href: '/catalog/muzhskoe/obuv/lofery-i-mokasiny',
          },
          {
            title: 'Sandals and Flip flops',
            href: '/catalog/muzhskoe/obuv/sandalii-i-shlepanci',
          },
          {
            title: 'Flats',
            href: '/catalog/muzhskoe/obuv/tufli',
          },
          {
            title: 'Espadrilles',
            href: '/catalog/muzhskoe/obuv/espadrili',
          },
        ],
      },
      {
        title: 'Clothing',
        href: '/catalog/muzhskoe/odezhda',
        items: [
          {
            title: 'Trousers and Shorts',
            href: '/catalog/muzhskoe/odezhda/bryuki-i-shorty',
          },
          {
            title: 'Outerwear',
            href: '/catalog/muzhskoe/odezhda/verhnyaya-odezhda',
          },
          {
            title: 'Jeans',
            href: '/catalog/muzhskoe/odezhda/dzhinsy',
          },
          {
            title: 'Suits',
            href: '/catalog/muzhskoe/odezhda/kostyumy',
          },
          {
            title: 'T-shirts and Singlets',
            href: '/catalog/muzhskoe/odezhda/majki-i-futbolki',
          },
          {
            title: 'Underwear and Homewear',
            href: '/catalog/muzhskoe/nizhnee-bele-i-domashnyaya-odezhda',
          },
          {
            title: 'Jackets',
            href: '/catalog/muzhskoe/odezhda/pidzhaki',
          },
        ],
      },
      {
        title: 'Bags',
        href: '/catalog/muzhskoe/sumki',
        items: [
          {
            title: 'Wrist Bags',
            href: '/catalog/muzhskoe/sumki/barsetki',
          },
          {
            title: 'Document folders',
            href: '/catalog/muzhskoe/sumki/papki-dlya-dokumentov',
          },
          {
            title: 'Briefcases',
            href: '/catalog/muzhskoe/sumki/portfeli',
          },
          {
            title: 'Belt Bags',
            href: '/catalog/muzhskoe/sumki/poyasnye-sumki',
          },
          {
            title: 'Backpacks',
            href: '/catalog/muzhskoe/sumki/ryukzaki',
          },
          {
            title: 'Sports and Travel Bags',
            href: '/catalog/muzhskoe/sumki/sportivnye-i-dorozhnye-sumki',
          },
          {
            title: 'Totes',
            href: '/catalog/muzhskoe/sumki/sumki-tout',
          },
        ],
      },
    ],
  },
  {
    title: 'Brands',
    href: '/brands/muzhskoe',
    items: [
      {
        title: 'Brands',
        items: [
          {
            title: 'ADIDAS YEEZY',
            href: '/catalog/adidasyeezy/muzhskoe',
          },
          {
            title: 'BRIONI',
            href: '/catalog/brioni/muzhskoe',
          },
          {
            title: 'BRUNELLO CUCINELLI',
            href: '/catalog/brunellocucinelli/muzhskoe',
          },
          {
            title: 'DOLCE&GABBANA',
            href: '/catalog/dolcegabbana/muzhskoe',
          },
          {
            title: 'DSQUARED2',
            href: '/catalog/dsquared2/muzhskoe',
          },
          {
            title: 'EMPORIO ARMANI',
            href: '/catalog/emporioarmani/muzhskoe',
          },
          {
            title: 'GUCCI',
            href: '/catalog/gucci/muzhskoe',
          },
          {
            title: 'HUGO BOSS',
            href: '/catalog/hugoboss/muzhskoe',
          },
        ],
      },
      {
        href: '/brands/muzhskoe',
        items: [
          {
            title: 'JOHN RICHMOND',
            href: '/catalog/johnrichmond/muzhskoe',
          },
          {
            title: 'LOUIS VUITTON',
            href: '/catalog/louis-vuitton/muzhskoe',
          },
          {
            title: 'PHILIPP PLEIN',
            href: '/catalog/philippplein/muzhskoe',
          },
          {
            title: 'PRADA',
            href: '/catalog/prada/muzhskoe',
          },
          {
            title: 'TOM FORD',
            href: '/catalog/tom-ford/muzhskoe',
          },
          {
            title: 'ZILLI',
            href: '/catalog/zilli/muzhskoe',
          },
        ],
      },
    ],
  },
  {
    title: 'Accessories',
    href: '/catalog/muzhskoe/aksessuary',
    items: [
      {
        title: 'Accessories',
        items: [
          {
            title: 'Smartphone accessories',
            href: '/catalog/muzhskoe/aksessuary/aksessuary-dlya-smartfona',
          },
          {
            title: 'Keyrings',
            href: '/catalog/muzhskoe/aksessuary/breloki',
          },
          {
            title: 'Business Card Holders',
            href: '/catalog/muzhskoe/aksessuary/vizitnicy',
          },
          {
            title: 'Ties and Bow ties',
            href: '/catalog/muzhskoe/aksessuary/galstuki-i-babochki',
          },
          {
            title: 'Hats',
            href: '/catalog/muzhskoe/aksessuary/golovnye-ubory',
          },
          {
            title: 'Key Cases',
            href: '/catalog/muzhskoe/aksessuary/klyuchnitsy',
          },
          {
            title: 'Cardholders',
            href: '/catalog/muzhskoe/aksessuary/koshelki-i-kardholdery',
          },
        ],
      },
      {
        href: '/catalog/muzhskoe/aksessuary',
        items: [
          {
            title: 'Glasses',
            href: '/catalog/muzhskoe/aksessuary/ochki',
          },
          {
            title: 'Gloves',
            href: '/catalog/muzhskoe/aksessuary/perchatki',
          },
          {
            title: 'Belts',
            href: '/catalog/muzhskoe/aksessuary/remni',
          },
          {
            title: 'Pens',
            href: '/catalog/muzhskoe/aksessuary/ruchki',
          },
          {
            title: 'Jewelry',
            href: '/catalog/muzhskoe/aksessuary/ukrasheniya',
          },
          {
            title: 'Watches',
            href: '/catalog/muzhskoe/aksessuary/chasy',
          },
          {
            title: 'Scarves',
            href: '/catalog/muzhskoe/aksessuary/sharfy',
          },
        ],
      },
    ],
  },
  {
    title: 'Beauty',
    href: '/catalog/muzhskoe/beauty',
    items: [
      {
        title: 'Beauty',
        href: '/catalog/muzhskoe/beauty',
        items: [
          {
            title: 'Accessories',
            href: '/catalog/muzhskoe/beauty/aksessuary',
          },
          {
            title: 'Barbershop',
            href: '/catalog/muzhskoe/beauty/barbershop',
          },
          {
            title: 'Deodorants',
            href: '/catalog/muzhskoe/beauty/dezodoranty',
          },
          {
            title: 'Fragrance',
            href: '/catalog/muzhskoe/beauty/parfyumeriya',
          },
        ],
      },
    ],
  },
  {
    title: 'Shoes',
    href: '/catalog/muzhskoe/obuv',
    items: [
      {
        title: 'Shoes',
        href: '/catalog/muzhskoe/obuv',
        items: [
          {
            title: 'Boots',
            href: '/catalog/muzhskoe/obuv/botinki',
          },
          {
            title: 'Sneakers',
            href: '/catalog/muzhskoe/obuv/krossovki-i-kedy',
          },
          {
            title: 'Loafers and Moccasins',
            href: '/catalog/muzhskoe/obuv/lofery-i-mokasiny',
          },
          {
            title: 'Sandals and Flip flops',
            href: '/catalog/muzhskoe/obuv/sandalii-i-shlepanci',
          },
          {
            title: 'Flats',
            href: '/catalog/muzhskoe/obuv/tufli',
          },
          {
            title: 'Espadrilles',
            href: '/catalog/muzhskoe/obuv/espadrili',
          },
        ],
      },
    ],
  },
  {
    title: 'Clothing',
    href: '/catalog/muzhskoe/odezhda',
    items: [
      {
        title: 'Clothing',
        items: [
          {
            title: 'Trousers and Shorts',
            href: '/catalog/muzhskoe/odezhda/bryuki-i-shorty',
          },
          {
            title: 'Outerwear',
            href: '/catalog/muzhskoe/odezhda/verhnyaya-odezhda',
          },
          {
            title: 'Jeans',
            href: '/catalog/muzhskoe/odezhda/dzhinsy',
          },
          {
            title: 'Suits',
            href: '/catalog/muzhskoe/odezhda/kostyumy',
          },
          {
            title: 'T-shirts and Singlets',
            href: '/catalog/muzhskoe/odezhda/majki-i-futbolki',
          },
          {
            title: 'Underwear and Homewear',
            href: '/catalog/muzhskoe/nizhnee-bele-i-domashnyaya-odezhda',
          },
          {
            title: 'Jackets',
            href: '/catalog/muzhskoe/odezhda/pidzhaki',
          },
        ],
      },
      {
        href: '/catalog/muzhskoe/odezhda',
        items: [
          {
            title: 'Swimwear',
            href: '/catalog/muzhskoe/odezhda/plyazhnaya-odezhda',
          },
          {
            title: 'Shirts',
            href: '/catalog/muzhskoe/odezhda/rubashki',
          },
          {
            title: 'Sportswear',
            href: '/catalog/muzhskoe/odezhda/sportivnaya-odezhda',
          },
          {
            title: 'Knitwear',
            href: '/catalog/muzhskoe/odezhda/trikotazh',
          },
          {
            title: 'Hoodies and Sweatshirts',
            href: '/catalog/muzhskoe/odezhda/hudi-i-tolstovki',
          },
        ],
      },
    ],
  },
  {
    title: 'Bags',
    href: '/catalog/muzhskoe/sumki',
    items: [
      {
        title: 'Bags',
        items: [
          {
            title: 'Wrist Bags',
            href: '/catalog/muzhskoe/sumki/barsetki',
          },
          {
            title: 'Document folders',
            href: '/catalog/muzhskoe/sumki/papki-dlya-dokumentov',
          },
          {
            title: 'Briefcases',
            href: '/catalog/muzhskoe/sumki/portfeli',
          },
          {
            title: 'Belt bags',
            href: '/catalog/muzhskoe/sumki/poyasnye-sumki',
          },
          {
            title: 'Backpacks',
            href: '/catalog/muzhskoe/sumki/ryukzaki',
          },
          {
            title: 'Sports and Travel Bags',
            href: '/catalog/muzhskoe/sumki/sportivnye-i-dorozhnye-sumki',
          },
          {
            title: 'Totes',
            href: '/catalog/muzhskoe/sumki/sumki-tout',
          },
        ],
      },
      {
        href: '/catalog/muzhskoe/sumki',
        items: [
          {
            title: 'Shoulder Bags',
            href: '/catalog/muzhskoe/sumki/sumki-na-plecho',
          },
        ],
      },
    ],
  },
  {
    title: 'Jewelry',
    href: '/catalog/muzhskoe/yuvelirnye-izdeliya',
    items: [
      {
        title: 'Jewelry',
        href: '/catalog/muzhskoe/yuvelirnye-izdeliya',
        items: [
          {
            title: 'Bracelets',
            href: '/catalog/muzhskoe/yuvelirnye-izdeliya/bracelety',
          },
          {
            title: 'Cufflinks',
            href: '/catalog/muzhskoe/yuvelirnye-izdeliya/zaponki',
          },
          {
            title: 'Rings',
            href: '/catalog/muzhskoe/yuvelirnye-izdeliya/koltsa',
          },
          {
            title: 'Earrings',
            href: '/catalog/muzhskoe/yuvelirnye-izdeliya/sergi',
          },
          {
            title: 'Necklaces',
            href: '/catalog/muzhskoe/yuvelirnye-izdeliya/tsepochki-i-podveski',
          },
          {
            title: 'Watches',
            href: '/catalog/muzhskoe/yuvelirnye-izdeliya/tchasy',
          },
        ],
      },
    ],
  },
];
