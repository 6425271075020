import { ICategoryMenu } from '~/types/menu';

export const lifestyle: ICategoryMenu[] = [
  {
    title: 'Новинки',
    href: '/catalog/lifestyle',
    items: [
      {
        title: 'Новинки',
        href: '/catalog/lifestyle',
        items: [
          {
            title: 'Home',
            href: '/catalog/lifestyle/home',
          },
          {
            title: 'Stargift',
            href: '/catalog/lifestyle/stargift',
          },
          {
            title: 'Техника',
            href: '/catalog/lifestyle/devices',
          },
        ],
      },
    ],
  },
  {
    title: 'Бренды',
    href: '/brands/lifestyle',
    items: [
      {
        title: 'Бренды',
        items: [
          {
            title: 'Hermes',
            href: '/catalog/hermes/lifestyle',
          },
          {
            title: 'Dyson',
            href: '/catalog/dyson/lifestyle',
          },
          {
            title: 'Kaws',
            href: '/catalog/kaws/lifestyle',
          },
          {
            title: 'Baccarat',
            href: '/catalog/baccarat/lifestyle',
          },
          {
            title: 'Daum',
            href: '/catalog/daum/lifestyle',
          },
          {
            title: 'Tiffany&Co',
            href: '/catalog/tiffanyco/lifestyle',
          },
          {
            title: 'Louis Vuitton',
            href: '/catalog/louis-vuitton/lifestyle',
          },
          {
            title: 'Versace',
            href: '/catalog/versace/lifestyle',
          },
        ],
      },
      {
        href: '/brands/lifestyle',
        items: [
          {
            title: 'Cartier',
            href: '/catalog/cartier/lifestyle',
          },
          {
            title: 'Gucci',
            href: '/catalog/gucci/lifestyle',
          },
          {
            title: 'Saint Louis',
            href: '/catalog/saintlouis/lifestyle',
          },
          {
            title: 'Lalique',
            href: '/catalog/lalique/lifestyle',
          },
          {
            title: 'Faberge',
            href: '/catalog/faberge/lifestyle',
          },
          {
            title: 'Villeroy&Boch',
            href: '/catalog/villeroyboch/lifestyle',
          },
        ],
      },
    ],
  },
  {
    title: 'Home',
    href: '/catalog/lifestyle/home',
    items: [
      {
        title: 'Home',
        href: '/catalog/lifestyle/home',
        items: [
          {
            title: 'Посуда',
            href: '/catalog/lifestyle/home/posuda',
          },
          {
            title: 'Предметы интерьера',
            href: '/catalog/lifestyle/home/predmety-interiyera',
          },
          {
            title: 'Текстиль',
            href: '/catalog/lifestyle/home/tekstil',
          },
          {
            title: 'Ароматы',
            href: '/catalog/lifestyle/home/aromaty',
          },
          {
            title: 'Книги',
            href: '/catalog/lifestyle/home/knigi',
          },
        ],
      },
    ],
  },
  {
    title: 'Stargifts',
    href: '/catalog/lifestyle/stargift',
    items: [
      {
        title: 'Stargifts',
        items: [
          {
            title: 'История',
            href: '/catalog/lifestyle/stargift/istoriya',
          },
          {
            title: 'Кино',
            href: '/catalog/lifestyle/stargift/kino',
          },
          {
            title: 'Музыка',
            href: '/catalog/lifestyle/stargift/muzyka',
          },
          {
            title: 'Спорт',
            href: '/catalog/lifestyle/stargift/sport',
          },
          {
            title: 'Именные обращения',
            href: '/catalog/lifestyle/stargift/imennyye-obrashcheniya',
          },
          {
            title: 'Эксклюзив',
            href: '/catalog/lifestyle/stargift/eksklyuziv',
          },

        ],
      },
      {
        href: '/catalog/lifestyle/stargift',
        items: [
          {
            title: 'Встречи',
            href: '/catalog/lifestyle/stargift/vstrechi',
          },
          {
            title: 'Новинки',
            href: '/catalog/lifestyle/stargift/novinki',
          },
          {
            title: 'Литература',
            href: '/catalog/lifestyle/stargift/literatura',
          },
          {
            title: 'Политика',
            href: '/catalog/lifestyle/stargift/politika',
          },
          {
            title: 'Искусство',
            href: '/catalog/lifestyle/stargift/iskusstvo',
          },
        ],
      },
    ],
  },
  {
    title: 'Техника',
    href: '/catalog/lifestyle/devices',
  },
];
