<template>
  <label class="osk-file-attach-avatar">
    <img
      v-if="base64Img"
      :src="base64Img.base64"
      class="osk-file-attach-avatar__image"
    >

    <vue-load-image
      v-else-if="urlImg"
      class="osk-file-attach-avatar__image"
    >
      <template #image>
        <img
          :src="urlImg"
          :alt="urlImg"
        >
      </template>

      <template #preloader>
        <UikitLoaderInner
          class="osk-file-attach-avatar__image-loader"
          small
        />
      </template>
    </vue-load-image>

    <UikitIcon
      v-else
      name="Photo"
      size="l"
    />

    <div
      v-if="base64Img"
      class="osk-file-attach-avatar__image-plus"
    >
      <UikitIcon
        name="Plus"
        size="s"
        color="white"
      />
    </div>

    <input
      ref="$inputAttach"
      class="osk-file-attach-avatar__input"
      type="file"
      @change="handleAttachFile"
    >
  </label>
</template>

<script setup lang="ts">
import VueLoadImage from 'vue-load-image';

import { img2Base64 } from '~/utils/files';

interface IAttachedImg {
  name: string,
  size: number,
  base64: string,
}

const props = defineProps<{
  base64Img?: IAttachedImg | null
  urlImg?: string
}>();

const emits = defineEmits<{
  (e: 'update:base64Img', value?: IAttachedImg): void,
  (e: 'update:urlImg', value?: string): void,
}>();

const $inputAttach = ref<HTMLInputElement | null>(null);

async function handleAttachFile(e: Event) {
  if (!$inputAttach.value) return;

  await genBase64([...($inputAttach.value.files ?? [])]);

  $inputAttach.value.value = '';
}

async function genBase64(files: File[]) {
  const addedFiles = files.filter((file) => file.type.match('image'));

  if (!addedFiles.length) return;

  const file = addedFiles[0];

  const base64Img = await img2Base64(file)
    .then((res) => ({
      name: res.img.name,
      size: res.img.size,
      base64: res.base64 as string,
    }));

  emits('update:base64Img', base64Img);
  emits('update:urlImg');
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-file-attach-avatar {
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 94px;
  height: 94px;
  background: $grey-4;
  border-radius: 100%;
  position: relative;

  &__input {
    display: none;
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
    display: block;
    object-fit: cover;
    flex-shrink: 0;
    position: relative;
  }

  &__image-plus {
    position: absolute;
    background: $black;
    width: 26px;
    height: 26px;
    border: 2px solid $white;
    border-radius: 100%;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image-loader {
    background: $grey-4;
  }
}
</style>
