<template>
  <UikitDialog
    :model-value="modelValue"
    position="right"
    full-height
    full-height-no-scroll
    :no-shadow="!isDesktop"
    :hide-backdrop="!isDesktop"
    close-others-on-mobile
    no-padding
    persistent
    :z-index="1100"
    :content-class="'osk-auth-register-dialog'"
    @update:model-value="updateModelValue"
    @handle-close="handleClose"
  >
    <a
      class="osk-auth-register-dialog__phone"
      href="tel:+78007075308"
      @click="handlePhone"
    >
      <UikitIcon
        name="Phone"
        size="l"
      />
    </a>

    <UikitTransitionCollapse
      :show="showError"
      :mb="16"
      class="osk-auth-register-dialog__caption"
    >
      <div class="osk-auth-register-dialog__error">
        {{ validateErrorMessage ?? registerErrorMessage ?? $t('auth.failedRegister') }}
      </div>
    </UikitTransitionCollapse>

    <UikitForm
      ref="$form"
      class="osk-auth-register-dialog__form"
      @submit-validate-success="submitSuccess"
      @submit-validate-error="handleSubmitError"
    >
      <div class="osk-global-dialog__title">
        {{ $t('auth.personalInformation') }}
      </div>

      <div class="osk-auth-register-dialog__dialog-wrapper">
        <UikitFormFileAttachAvatar
          v-model:base64Img="formData.base64Img"
          v-model:urlImg="formData.avatarUrl"
          class="osk-auth-register-dialog__file-attach"
        />
        <template v-if="isAuthBySocial">
          <UikitFormInput
            ref="$emailInput"
            v-model="formData.email"
            name="email"
            label="E-mail"
            rules="required|email"
            required
            :disabled="registerOptions?.emailEditDisabled"
          />

          <UikitFormPhoneInput
            ref="$phoneInput"
            v-model="phoneNumber"
            name="phone"
            :label="$t('auth.phoneNumberLabel')"
            :only-countries="onlyCountriesComp"
            required
            rules="phone2"
            :disabled="registerOptions?.phoneNumberEditDisabled"
          />

          <UikitFormErrorMessage name="globalError" />
        </template>

        <template v-else>
          <UikitFormPhoneInput
            ref="$phoneInput"
            v-model="phoneNumber"
            name="phone"
            :label="$t('auth.phoneNumberLabel')"
            :only-countries="onlyCountriesComp"
            required
            rules="phone2"
            :disabled="!!registerDialogData?.phoneNumber"
          />

          <UikitFormInput
            ref="$emailInput"
            v-model="formData.email"
            name="email"
            label="E-mail"
            rules="required|email"
            required
          />
        </template>

        <UikitFormInput
          ref="$nicknameInput"
          v-model="formData.nickname"
          name="nickname"
          :label="$t('auth.userName')"
          required
        />

        <UikitFormCheckbox
          v-model="formData.subscriptionApprove"
          class="osk-auth-register-dialog__checkbox"
          name="subscriptionApprove"
          :label="$t('auth.AuthRegisterDialog.checkbox1')"
        />

        <UikitFormCheckbox
          v-model="formData.agree"
          name="agree"
          required
        >
          <template #label>
            <i18n-t keypath="auth.AuthRegisterDialog.checkbox2">
              <template #buyerLicense>
                <a
                  :href="isInternational ? 'https://cdn.files.oskelly.co/info/pdf/TOS.pdf' : 'https://oskelly.ru/info/pdf/buyer-license.pdf'"
                  target="_blank"
                  class="osk-auth-register-dialog__link"
                >
                  {{ $t('auth.AuthRegisterDialog.checkbox2.buyerLicense') }}
                </a>
              </template>

              <template #sellerAgencyAgreement>
                <a
                  :href="isInternational ? 'https://cdn.files.oskelly.co/info/pdf/agency-agreement.pdf' : 'https://oskelly.ru/info/pdf/seller-agency-agreement.pdf'"
                  target="_blank"
                  class="osk-auth-register-dialog__link"
                >
                  {{ $t('auth.AuthRegisterDialog.checkbox2.sellerAgencyAgreement') }}
                </a>
              </template>

              <template #userAgreement>
                <a
                  :href="isInternational ? 'https://cdn.files.oskelly.co/info/pdf/privacy-policy.pdf' : 'https://oskelly.ru/info/pdf/user-agreement.pdf'"
                  target="_blank"
                  class="osk-auth-register-dialog__link"
                >
                  {{ $t('auth.AuthRegisterDialog.checkbox2.userAgreement') }}
                </a>
              </template>
            </i18n-t>
          </template>
        </UikitFormCheckbox>
      </div>

      <div class="osk-auth-register-dialog__dialog-footer">
        <UikitButton
          class="osk-auth-register-dialog__button"
          full-width
          :loading="loading"
          :disabled="!formData.agree"
        >
          <template v-if="isAuthBySocial">
            {{ $t('auth.register.continue') }}
          </template>

          <template v-else>
            {{ $t('auth.register.forShopping') }}
          </template>
        </UikitButton>
      </div>
    </UikitForm>

    <template #dialogs>
      <AuthSmsDialog
        v-model="authSmsDialog"
        :formatted-phone="formattedPhone"
        :phone="phoneNumber"
        :only-countries="onlyCountries"
        :token="verificationToken"
        :loading="generateCodePending"
        :error="generateCodeError?.data?.message"
        :z-index="1101"
        auth
        register
        operation="VERIFY_PHONE_NUMBER"
        @resend="resendSmsCode"
        @register="handleAuthRegister"
      />
    </template>
  </UikitDialog>
</template>

<script setup lang="ts">
import { AnalyticsEvents } from '~/types/analytics';
import type { TSubmitSuccess } from '~/types/components/form';

import { useApi } from '~/restAPI';
import { Account } from '~/restAPI/Account';

type TFormFieldName = 'phoneNumber' | 'email' | 'nickname';

interface IForm {
  phoneNumber: string
  email?: string
  nickname?: string
  subscriptionApprove?: boolean
}

interface IAttachedImg {
  name: string,
  size: number,
  base64: string,
}

const props = defineProps<{
  modelValue: boolean
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
  (e: 'success'): void,
}>();

const page = 'profile_reg_ferst';

const $form = ref();
const showError = ref(false);

const {
  setAuthSuccess,
  registerDialogData,
  authDialogChapter,
} = useAccountStore();
const { isInternational } = useInternational();

const formData = ref({
  email: '',
  nickname: '',
  subscriptionApprove: false,
  agree: false,
  base64Img: null as (IAttachedImg | null),
  avatarUrl: '',
});

const phoneNumberVerifyJwtToken = ref('');
const isPhoneChanged = ref(false);
const $emailInput = ref();
const $nicknameInput = ref();

const { createApiInstance } = useApi();
const { isDesktop } = useUiBreakpoints();
const { $addEvent } = useNuxtApp();

const accountApi = createApiInstance(Account);

const {
  loading,
  $phoneInput,
  getCountries,
  onlyCountries,
  submitSuccess: generateOtpCode,
  authSmsDialog,
  formattedPhone,
  phoneNumber,
  verificationToken,
  generateCodePending,
  generateCodeError,
  resendSmsCode,
} = usePhoneVerification();

const socialAuthData = useSocialAuthData();

const {
  register,
  registerError, registerData,
} = useAuthSocial({
  formData,
  phoneNumber,
  phoneNumberVerifyJwtToken,
});

const registerErrorData = computed(() => registerError.value?.data?.errorData);
const registerOptions = computed(() => socialAuthData.value?.registrationOptions);

const onlyCountriesComp = computed(() => registerDialogData.value?.onlyCountries ?? onlyCountries.value);

const isAuthBySocial = computed(() => !!socialAuthData.value);

const {
  data: IdAndJwt,
  execute: phoneNumberRegister,
  error: phoneNumberRegisterError,
} = useLazyAsyncData(() => accountApi.phoneNumberRegisterUsingPost({
  authInfo: {
    phoneNumber: phoneNumber.value,
    jwtToken: registerDialogData.value?.jwtToken ?? '',
  },
  registerForm: {
    email: formData.value.email,
    phone: phoneNumber.value,
    nickname: formData.value.nickname,
    subscriptionApprove: formData.value.subscriptionApprove,
    avatarBase64: formData.value.base64Img?.base64,
  },
}).then((res) => res?.data?.data), { immediate: false });

const {
  execute: validateRegisterForm,
  error: validateRegisterFormError,
} = useLazyAsyncData(() => accountApi.validateRegisterFormUsingPost({
  email: formData.value.email,
  phone: phoneNumber.value,
  nickname: formData.value.nickname,
  avatarUrl: formData.value.avatarUrl,
  avatarBase64: formData.value.base64Img?.base64,
  subscriptionApprove: formData.value.subscriptionApprove,
}).then((res) => res?.data?.data), { immediate: false });

const errorData = computed(() => phoneNumberRegisterError.value?.data?.errorData);
const validateErrors = computed(() => validateRegisterFormError.value?.data?.validationMessages);
const validateErrorMessage = computed(() => validateRegisterFormError.value?.data?.message);

const registerErrorMessage = computed(() => registerError.value?.data?.humanMessage
  ?? phoneNumberRegisterError.value?.data?.humanMessage);

function handleAuthRegister(jwt?: string) {
  isPhoneChanged.value = false;

  handleRegister(jwt);
}

function registerEvent() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'confirm',
    item: formData.value.subscriptionApprove ? 'on' : undefined,
  });
}

async function handleRegister(jwt?: string) {
  phoneNumberVerifyJwtToken.value = jwt ?? '';
  loading.value = true;

  await register();

  if (registerErrorData.value && $form.value) {
    Object.keys(registerErrorData.value).forEach((name) => {
      $form.value.setFieldError(name as TFormFieldName, registerErrorData.value[name]);
    });

    loading.value = false;
    return;
  }

  if (registerError.value) {
    showError.value = true;
    loading.value = false;

    return;
  }

  if (registerData.value) {
    setAuthSuccess();
    $addEvent(AnalyticsEvents.Registration, { page });
    return;
  }

  loading.value = false;
}

async function socialRegister(data: TSubmitSuccess<IForm>) {
  await validateRegisterForm();

  if (validateErrors.value) {
    Object.keys((validateErrors.value ?? [])).forEach((name) => {
      data.actions.setFieldError(name as TFormFieldName, validateErrors.value[name]);

      $addEvent(AnalyticsEvents.BlockView, {
        page,
        action: 'error',
        item: name,
      });
    });

    loading.value = false;
    return;
  }

  if (validateErrorMessage.value) {
    showError.value = true;
    loading.value = false;

    return;
  }

  const needPhoneVerify = registerOptions.value?.phoneNumberVerificationNeed;

  if (!needPhoneVerify || (phoneNumberVerifyJwtToken.value && !isPhoneChanged.value)) {
    handleRegister(phoneNumberVerifyJwtToken.value);
    return;
  }

  await generateOtpCode(data);
}

async function submitSuccess(data: TSubmitSuccess<IForm>) {
  const { actions } = data;

  $form.value.setFieldError('globalError', undefined);
  showError.value = false;
  loading.value = true;

  registerEvent();

  if (isAuthBySocial.value) {
    socialRegister(data);
    return;
  }

  await phoneNumberRegister();

  if (errorData.value) {
    Object.keys(errorData.value).forEach((name) => {
      actions.setFieldError(name as TFormFieldName, errorData.value[name]);

      $addEvent(AnalyticsEvents.BlockView, {
        page,
        action: 'error',
        item: name,
      });
    });

    loading.value = false;
    return;
  }

  if (phoneNumberRegisterError.value) {
    showError.value = true;
    loading.value = false;
    return;
  }

  if (IdAndJwt.value) {
    setAuthSuccess();
    $addEvent(AnalyticsEvents.Registration, { page });
    return;
  }

  loading.value = false;
}

function handleSubmitError() {
  $form.value.setFieldError('globalError', undefined);

  registerEvent();
}

function updateModelValue(value: boolean) {
  emits('update:modelValue', value);
}

function handlePhone() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'contact_mobile',
  });
}

function handleClose() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'back',
  });
}

watch(phoneNumber, () => {
  isPhoneChanged.value = true;
});

watch(() => props.modelValue, async (value) => {
  if (!value) {
    authDialogChapter.value = undefined;
    showError.value = false;

    setTimeout(() => {
      socialAuthData.value = undefined;
      phoneNumber.value = '';
      formData.value = {
        email: '',
        nickname: '',
        subscriptionApprove: false,
        agree: false,
        base64Img: null,
        avatarUrl: '',
      };
    }, 200);
    return;
  }

  if (socialAuthData.value) {
    const { registerForm } = socialAuthData.value;

    formData.value.email = registerForm?.email ?? '';
    formData.value.avatarUrl = registerForm?.avatarUrl ?? '';
    formData.value.nickname = registerForm?.nickname ?? registerForm?.email?.split('@')[0] ?? '';
    phoneNumber.value = registerForm?.phone ?? '';
  } else if (registerDialogData.value?.phoneNumber) {
    phoneNumber.value = registerDialogData.value.phoneNumber;
  }

  setTimeout(() => {
    if (isAuthBySocial.value) {
      if (!registerOptions.value?.emailEditDisabled) {
        $emailInput.value?.setFocus();
      } else if (!registerOptions.value?.phoneNumberEditDisabled) {
        $phoneInput.value?.setFocus();
      } else {
        $nicknameInput.value?.setFocus();
      }
    } else {
      $emailInput.value?.setFocus();
    }
  }, 0);

  if (!registerDialogData.value?.onlyCountries) {
    await getCountries();
  }

  $addEvent(AnalyticsEvents.BlockView, {
    page,
    chapter: authDialogChapter.value,
  });
});

onUnmounted(() => {
  socialAuthData.value = undefined;
  phoneNumber.value = '';
  authDialogChapter.value = undefined;
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-auth-register-dialog {
  @include font-body-all;
  display: flex !important;
  flex-direction: column;
  overflow-y: visible;
  padding-top: 68px;

  @include media-query(lg-and-up) {
    padding-top: 0;
  }

  &__dialog-wrapper {
    overflow-y: scroll;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 16px 0;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-query(lg-and-up) {
      padding: 0 52px;
      justify-content: flex-start;
    }
  }

  &__dialog-footer {
    padding: 16px;

    @include media-query(lg-and-up) {
      padding: 16px 52px 52px;
    }
  }

  .osk-global-dialog__title {
    padding: 16px;
    margin-bottom: 0;

    @include media-query(lg-and-up) {
      padding: 0 52px;
      margin-bottom: 16px;
    }
  }

  .osk-dialog__close {
    right: auto;
    left: 12px;
    top: 16px;

    @include media-query(lg-and-up) {
      right: auto;
      left: 48px;
      top: 44px;
    }
  }

  &__phone {
    position: absolute;
    cursor: pointer;
    right: 16px;
    top: 16px;

    @include media-query(lg-and-up) {
      right: 52px;
      top: 44px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    height: calc(100% - 68px);
    flex-grow: 1;

    @include media-query(lg-and-up) {
      width: 504px;
      padding-top: 140px;

      @media screen and (max-height: 680px) {
        padding-top: 90px;
      }
    }
  }

  &__file-attach {
    margin-bottom: 32px;
    margin-top: 16px;

    @include media-query(lg-and-up) {
      margin-top: 8px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  }

  &__item {
    width: 41px;
    height: 41px;
    border: 1px solid $grey-3;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px;
    cursor: pointer;
    transition: all linear .2s;

    @include media-query(lg-and-up) {
      &:hover {
        background: $grey-4;
        border: 1px solid $grey-4;

        .osk-auth-register-dialog__item-mail-icon {
          color: $black;
        }
      }
    }
  }

  &__item_mail {
    background: $grey-4;
    border: 0;

    @include media-query(lg-and-up) {
      &:hover {
        background: $grey-4;
        border: 0;
      }
    }
  }

  &__item-mail-icon {
    width: 20px;
    height: 17px;
    color: $grey;
    transition: all linear .2s;
  }

  &__caption {
    margin: 0 16px;

    @include media-query(lg-and-up) {
      position: absolute;
      left: 52px;
      right: 52px;
      top: 94px;
      margin: 0;
    }
  }

  &__checkbox {
    margin-top: 40px !important;
  }

  &__link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__error {
    border-radius: 2px;
    background: $red;
    height: 31px;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
}
</style>
