<template>
  <UikitDialog
    v-model="isOpen"
    position="right"
    full-height
    full-height-no-scroll
    :no-shadow="!isDesktop"
    :hide-backdrop="!isDesktop"
    close-others-on-mobile
    no-padding
    :z-index="1100"
    content-class="osk-global-dialog osk-country-dialog"
  >
    <div class="osk-country-dialog__title">
      {{ $t('components.country.countryDialog.title') }}
    </div>

    <div class="osk-country-dialog__select">
      <UikitFormSelect
        v-model="selectedCountry"
        name="select"
        max-height="336px"
      >
        <UikitFormSelectCountryOption
          v-for="country in countries"
          :key="country.id"
          :value="country"
          :label="getCountryLabel(country)"
        />
      </UikitFormSelect>
    </div>

    <UikitButton
      class="osk-country-dialog__button"
      full-width
      @click="handleSubmit"
    >
      {{ $t('components.country.countryDialog.submit') }}
    </UikitButton>
  </UikitDialog>
</template>

<script setup lang="ts">
import { CountryDTO } from '~/restAPI/data-contracts';

const isOpen = defineModel<boolean>({ required: true });

const selectedCountry = ref<CountryDTO | null>(null);

const { currentCountry, countries, changeCountry } = useInternational();

const { isDesktop } = useUiBreakpoints();

function handleClose() {
  isOpen.value = false;
}

function getCountryLabel(country: CountryDTO) {
  return [ country.name, country.currency?.isoCode ].join(', ');
}

function handleSubmit() {
  if (selectedCountry.value) {
    changeCountry(selectedCountry.value);
  }

  handleClose();
}

watch(() => isOpen.value, async (value) => {
  if (value) {
    if (currentCountry.value) {
      selectedCountry.value = currentCountry.value;
    }
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-country-dialog {
  justify-content: center !important;

  &__title {
    font-size: 18px;
    line-height: normal;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__button {
    margin-top: 24px;
  }
}
</style>
