import { ICategoryMenu } from '~/types/menu';

export const women: ICategoryMenu[] = [
  {
    title: 'Выбор Oskelly',
    href: '/catalog/zhenskoe?oskellyChoice=true',
    items: [
      {
        title: 'Аксессуары',
        href: '/catalog/zhenskoe/aksessuary?oskellyChoice=true',
        items: [
          {
            title: 'Визитницы',
            href: '/catalog/zhenskoe/aksessuary/vizitnicy?oskellyChoice=true',
          },
          {
            title: 'Головные уборы',
            href: '/catalog/zhenskoe/aksessuary/golovnye-ubory?oskellyChoice=true',
          },
          {
            title: 'Кошельки и кардхолдеры',
            href: '/catalog/zhenskoe/aksessuary/koshelki-i-kardholdery?oskellyChoice=true',
          },
          {
            title: 'Ободки',
            href: '/catalog/zhenskoe/aksessuary/obodki?oskellyChoice=true',
          },
          {
            title: 'Очки',
            href: '/catalog/zhenskoe/aksessuary/ochki?oskellyChoice=true',
          },
          {
            title: 'Перчатки',
            href: '/catalog/zhenskoe/aksessuary/perchatki?oskellyChoice=true',
          },
          {
            title: 'Повязки на голову',
            href: '/catalog/zhenskoe/aksessuary/povyazki-na-golovu?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Бьюти',
        href: '/catalog/zhenskoe/beauty?oskellyChoice=true',
        items: [
          {
            title: 'Парфюмерия',
            href: '/catalog/zhenskoe/beauty/parfyumeriya?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Обувь',
        href: '/catalog/zhenskoe/obuv?oskellyChoice=true',
        items: [
          {
            title: 'Балетки',
            href: '/catalog/zhenskoe/obuv/baletki?oskellyChoice=true',
          },
          {
            title: 'Босоножки',
            href: '/catalog/zhenskoe/obuv/bosonozhki?oskellyChoice=true',
          },
          {
            title: 'Ботильоны и полусапоги',
            href: '/catalog/zhenskoe/obuv/botilony/botilony-i-polusapogi?oskellyChoice=true',
          },
          {
            title: 'Ботинки',
            href: '/catalog/zhenskoe/obuv/botinki?oskellyChoice=true',
          },
          {
            title: 'Брогги и оксфорды',
            href: '/catalog/zhenskoe/obuv/broggi-i-oksfordy?oskellyChoice=true',
          },
          {
            title: 'Кроссовки и кеды',
            href: '/catalog/zhenskoe/obuv/krossovki-i-kedy?oskellyChoice=true',
          },
          {
            title: 'Лодочки на низком каблуке',
            href: '/catalog/zhenskoe/obuv/lodochki-na-nizkom-kabluke?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Одежда',
        href: '/catalog/zhenskoe/odezhda?oskellyChoice=true',
        items: [
          {
            title: 'Брюки',
            href: '/catalog/zhenskoe/odezhda/bryuki?oskellyChoice=true',
          },
          {
            title: 'Верхняя одежда',
            href: '/catalog/zhenskoe/odezhda/verhnyaya-odezhda?oskellyChoice=true',
          },
          {
            title: 'Джемперы, кардиганы, трикотаж',
            href: '/catalog/zhenskoe/odezhda/dzhempery-kardigany-trikotazh?oskellyChoice=true',
          },
          {
            title: 'Джинсы',
            href: '/catalog/zhenskoe/odezhda/dzhinsy?oskellyChoice=true',
          },
          {
            title: 'Жакеты и пиджаки',
            href: '/catalog/zhenskoe/odezhda/zhakety-i-pidzhaki?oskellyChoice=true',
          },
          {
            title: 'Костюмы',
            href: '/catalog/zhenskoe/odezhda/kostyumy?oskellyChoice=true',
          },
          {
            title: 'Майки и футболки',
            href: '/catalog/zhenskoe/odezhda/majki-i-futbolki?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Сумки',
        href: '/catalog/zhenskoe/sumki?oskellyChoice=true',
        items: [
          {
            title: 'Дорожные и спортивные сумки',
            href: '/catalog/zhenskoe/sumki/dorozhnye-i-sportivnye-sumki?oskellyChoice=true',
          },
          {
            title: 'Клатчи и вечерние сумки',
            href: '/catalog/zhenskoe/sumki/klatchi-i-vechernie-sumki?oskellyChoice=true',
          },
          {
            title: 'Пляжные сумки',
            href: '/catalog/zhenskoe/sumki/plyazhnye-sumki?oskellyChoice=true',
          },
          {
            title: 'Поясные сумки',
            href: '/catalog/zhenskoe/sumki/poyasnye-sumki?oskellyChoice=true',
          },
          {
            title: 'Рюкзаки',
            href: '/catalog/zhenskoe/sumki/ryukzaki?oskellyChoice=true',
          },
          {
            title: 'Сумки с короткими ручками',
            href: '/catalog/zhenskoe/sumki/sumki-s-korotkimi-ruchkami?oskellyChoice=true',
          },
          {
            title: 'Сумки тоут',
            href: '/catalog/zhenskoe/sumki/sumki-tout?oskellyChoice=true',
          },
        ],
      },
    ],
  },
  {
    title: 'Новинки',
    href: '/catalog/zhenskoe',
    items: [
      {
        title: 'Аксессуары',
        href: '/catalog/zhenskoe/aksessuary',
        items: [
          {
            title: 'Аксессуары для волос',
            href: '/catalog/zhenskoe/aksessuary/aksessuary-dlya-volos',
          },
          {
            title: 'Аксессуары для техники',
            href: '/catalog/zhenskoe/aksessuary/aksessuary-dlya-tehniki',
          },
          {
            title: 'Брелоки',
            href: '/catalog/zhenskoe/aksessuary/breloki',
          },
          {
            title: 'Визитницы',
            href: '/catalog/zhenskoe/aksessuary/vizitnicy',
          },
          {
            title: 'Головные уборы',
            href: '/catalog/zhenskoe/aksessuary/golovnye-ubory',
          },
          {
            title: 'Зонты',
            href: '/catalog/zhenskoe/aksessuary/zonty',
          },
          {
            title: 'Ключницы',
            href: '/catalog/zhenskoe/aksessuary/klyuchnitsy',
          },
        ],
      },
      {
        title: 'Бьюти',
        href: '/catalog/zhenskoe/beauty',
        items: [
          {
            title: 'Девайсы',
            href: '/catalog/zhenskoe/beauty/devaysy',
          },
          {
            title: 'Макияж',
            href: '/catalog/zhenskoe/beauty/makiyazh',
          },
          {
            title: 'Парфюмерия',
            href: '/catalog/zhenskoe/beauty/parfyumeriya',
          },
          {
            title: 'Уход за волосами',
            href: '/catalog/zhenskoe/beauty/ukhod-za-volosami',
          },
          {
            title: 'Уход за лицом',
            href: '/catalog/zhenskoe/beauty/ukhod-za-litsom',
          },
          {
            title: 'Уход за телом',
            href: '/catalog/zhenskoe/beauty/ukhod-za-telom',
          },
        ],
      },
      {
        title: 'Обувь',
        href: '/catalog/zhenskoe/obuv',
        items: [
          {
            title: 'Балетки',
            href: '/catalog/zhenskoe/obuv/baletki',
          },
          {
            title: 'Босоножки',
            href: '/catalog/zhenskoe/obuv/bosonozhki',
          },
          {
            title: 'Ботильоны и полусапоги',
            href: '/catalog/zhenskoe/obuv/botilony/botilony-i-polusapogi',
          },
          {
            title: 'Ботинки',
            href: '/catalog/zhenskoe/obuv/botinki',
          },
          {
            title: 'Брогги и оксфорды',
            href: '/catalog/zhenskoe/obuv/broggi-i-oksfordy',
          },
          {
            title: 'Кроссовки и кеды',
            href: '/catalog/zhenskoe/obuv/krossovki-i-kedy',
          },
          {
            title: 'Лодочки на низком каблуке',
            href: '/catalog/zhenskoe/obuv/lodochki-na-nizkom-kabluke',
          },
        ],
      },
      {
        title: 'Одежда',
        href: '/catalog/zhenskoe/odezhda',
        items: [
          {
            title: 'Брюки',
            href: '/catalog/zhenskoe/odezhda/bryuki',
          },
          {
            title: 'Верхняя одежда',
            href: '/catalog/zhenskoe/odezhda/verhnyaya-odezhda',
          },
          {
            title: 'Джемперы, кардиганы и трикотаж',
            href: '/catalog/zhenskoe/odezhda/dzhempery-kardigany-trikotazh',
          },
          {
            title: 'Джинсы',
            href: '/catalog/zhenskoe/odezhda/dzhinsy',
          },
          {
            title: 'Жакеты и пиджаки',
            href: '/catalog/zhenskoe/odezhda/zhakety-i-pidzhaki',
          },
          {
            title: 'Капри',
            href: '/catalog/zhenskoe/odezhda/kapri',
          },
          {
            title: 'Комбинезоны',
            href: '/catalog/zhenskoe/odezhda/kombinezony',
          },
        ],
      },
      {
        title: 'Сумки',
        href: '/catalog/zhenskoe/sumki',
        items: [
          {
            title: 'Аксессуары для сумок',
            href: '/catalog/zhenskoe/sumki/aksessuary-dlya-sumok',
          },
          {
            title: 'Дорожные и спортивные сумки',
            href: '/catalog/zhenskoe/sumki/dorozhnye-i-sportivnye-sumki',
          },
          {
            title: 'Клатчи и вечерние сумки',
            href: '/catalog/zhenskoe/sumki/klatchi-i-vechernie-sumki',
          },
          {
            title: 'Пляжные сумки',
            href: '/catalog/zhenskoe/sumki/plyazhnye-sumki',
          },
          {
            title: 'Поясные сумки',
            href: '/catalog/zhenskoe/sumki/poyasnye-sumki',
          },
          {
            title: 'Рюкзаки',
            href: '/catalog/zhenskoe/sumki/ryukzaki',
          },
          {
            title: 'Сумки с короткими ручками',
            href: '/catalog/zhenskoe/sumki/sumki-s-korotkimi-ruchkami',
          },
        ],
      },
    ],
  },
  {
    title: 'Бренды',
    href: '/brands/zhenskoe',
    items: [
      {
        title: 'Бренды',
        items: [
          {
            title: 'BALENCIAGA',
            href: '/catalog/balenciaga/zhenskoe',
          },
          {
            title: 'CELINE',
            href: '/catalog/celine/zhenskoe',
          },
          {
            title: 'CHANEL',
            href: '/catalog/chanel/zhenskoe',
          },
          {
            title: 'CHRISTIAN DIOR',
            href: '/catalog/christian-dior/zhenskoe',
          },
          {
            title: 'CHRISTIAN LOUBOUTIN',
            href: '/catalog/christianlouboutin/zhenskoe',
          },
          {
            title: 'DOLCE&GABBANA',
            href: '/catalog/dolcegabbana/zhenskoe',
          },
          {
            title: 'FENDI',
            href: '/catalog/fendi/zhenskoe',
          },
          {
            title: 'GUCCI',
            href: '/catalog/gucci/zhenskoe',
          },
        ],
      },
      {
        href: '/brands/zhenskoe',
        items: [
          {
            title: 'LOUIS VUITTON',
            href: '/catalog/louis-vuitton/zhenskoe',
          },
          {
            title: 'MICHAEL KORS',
            href: '/catalog/michaelkors/zhenskoe',
          },
          {
            title: 'PINKO',
            href: '/catalog/pinko/zhenskoe',
          },
          {
            title: 'PRADA',
            href: '/catalog/prada/zhenskoe',
          },
          {
            title: 'SAINT LAURENT',
            href: '/catalog/saintlaurent/zhenskoe',
          },
          {
            title: 'VALENTINO',
            href: '/catalog/valentino/zhenskoe',
          },
        ],
      },
    ],
  },
  {
    title: 'Аксессуары',
    href: '/catalog/zhenskoe/aksessuary',
    items: [
      {
        title: 'Аксессуары',
        items: [
          {
            title: 'Аксессуары для волос',
            href: '/catalog/zhenskoe/aksessuary/aksessuary-dlya-volos',
          },
          {
            title: 'Аксессуары для техники',
            href: '/catalog/zhenskoe/aksessuary/aksessuary-dlya-tehniki',
          },
          {
            title: 'Брелоки',
            href: '/catalog/zhenskoe/aksessuary/breloki',
          },
          {
            title: 'Визитницы',
            href: '/catalog/zhenskoe/aksessuary/vizitnicy',
          },
          {
            title: 'Головные уборы',
            href: '/catalog/zhenskoe/aksessuary/golovnye-ubory',
          },
          {
            title: 'Зонты',
            href: '/catalog/zhenskoe/aksessuary/zonty',
          },
          {
            title: 'Ключницы',
            href: '/catalog/zhenskoe/aksessuary/klyuchnitsy',
          },
        ],
      },
      {
        items: [
          {
            title: 'Косметички',
            href: '/catalog/zhenskoe/aksessuary/kosmetichki',
          },
          {
            title: 'Кошельки и кардхолдеры',
            href: '/catalog/zhenskoe/aksessuary/koshelki-i-kardholdery',
          },
          {
            title: 'Обложки и футляры',
            href: '/catalog/zhenskoe/aksessuary/oblozhki-i-futlyary',
          },
          {
            title: 'Ободки',
            href: '/catalog/zhenskoe/aksessuary/obodki',
          },
          {
            title: 'Очки',
            href: '/catalog/zhenskoe/aksessuary/ochki',
          },
          {
            title: 'Перчатки',
            href: '/catalog/zhenskoe/aksessuary/perchatki',
          },
          {
            title: 'Повязки на голову',
            href: '/catalog/zhenskoe/aksessuary/povyazki-na-golovu',
          },
        ],
      },
      {
        href: '/catalog/zhenskoe/aksessuary',
        items: [
          {
            title: 'Ремни',
            href: '/catalog/zhenskoe/aksessuary/remni',
          },
          {
            title: 'Шарфы и платки',
            href: '/catalog/zhenskoe/aksessuary/sharfy-i-platki',
          },
        ],
      },
    ],
  },
  {
    title: 'Бьюти',
    href: '/catalog/zhenskoe/beauty',
    items: [
      {
        title: 'Бьюти',
        href: '/catalog/zhenskoe/beauty',
        items: [
          {
            title: 'Девайсы',
            href: '/catalog/zhenskoe/beauty/devaysy',
          },
          {
            title: 'Макияж',
            href: '/catalog/zhenskoe/beauty/makiyazh',
          },
          {
            title: 'Парфюмерия',
            href: '/catalog/zhenskoe/beauty/parfyumeriya',
          },
          {
            title: 'Уход за волосами',
            href: '/catalog/zhenskoe/beauty/ukhod-za-volosami',
          },
          {
            title: 'Уход за лицом',
            href: '/catalog/zhenskoe/beauty/ukhod-za-litsom',
          },
          {
            title: 'Уход за телом',
            href: '/catalog/zhenskoe/beauty/ukhod-za-telom',
          },
        ],
      },
    ],
  },
  {
    title: 'Обувь',
    href: '/catalog/zhenskoe/obuv',
    items: [
      {
        title: 'Обувь',
        items: [
          {
            title: 'Балетки',
            href: '/catalog/zhenskoe/obuv/baletki',
          },
          {
            title: 'Босоножки',
            href: '/catalog/zhenskoe/obuv/bosonozhki',
          },
          {
            title: 'Ботильоны и полусапоги',
            href: '/catalog/zhenskoe/obuv/botilony/botilony-i-polusapogi',
          },
          {
            title: 'Ботинки',
            href: '/catalog/zhenskoe/obuv/botinki',
          },
          {
            title: 'Брогги и оксфорды',
            href: '/catalog/zhenskoe/obuv/broggi-i-oksfordy',
          },
          {
            title: 'Кроссовки и кеды',
            href: '/catalog/zhenskoe/obuv/krossovki-i-kedy',
          },
          {
            title: 'Лодочки на низком каблуке',
            href: '/catalog/zhenskoe/obuv/lodochki-na-nizkom-kabluke',
          },
          {
            title: 'Лоферы и мокасины',
            href: '/catalog/zhenskoe/obuv/lofery-i-mokasiny',
          },
        ],
      },
      {
        href: '/catalog/zhenskoe/obuv',
        items: [
          {
            title: 'Мюли и сабо',
            href: '/catalog/zhenskoe/obuv/myuli-i-sabo',
          },
          {
            title: 'Сандалии и шлепанцы',
            href: '/catalog/zhenskoe/obuv/sandalii-i-shlepancy',
          },
          {
            title: 'Сапоги и ботфорты',
            href: '/catalog/zhenskoe/obuv/sapogi-i-botforty',
          },
          {
            title: 'Свадебные туфли',
            href: '/catalog/zhenskoe/obuv/svadebnye-tufli',
          },
          {
            title: 'Туфли',
            href: '/catalog/zhenskoe/obuv/tufli',
          },
          {
            title: 'Эспадрильи',
            href: '/catalog/zhenskoe/obuv/espadrili',
          },
        ],
      },
    ],
  },
  {
    title: 'Одежда',
    href: '/catalog/zhenskoe/odezhda',
    items: [
      {
        title: 'Одежда',
        items: [
          {
            title: 'Брюки',
            href: '/catalog/zhenskoe/odezhda/bryuki',
          },
          {
            title: 'Верхняя одежда',
            href: '/catalog/zhenskoe/odezhda/verhnyaya-odezhda',
          },
          {
            title: 'Джемперы, кардиганы и трикотаж',
            href: '/catalog/zhenskoe/odezhda/dzhempery-kardigany-trikotazh',
          },
          {
            title: 'Джинсы',
            href: '/catalog/zhenskoe/odezhda/dzhinsy',
          },
          {
            title: 'Жакеты и пиджаки',
            href: '/catalog/zhenskoe/odezhda/zhakety-i-pidzhaki',
          },
          {
            title: 'Капри',
            href: '/catalog/zhenskoe/odezhda/kapri',
          },
          {
            title: 'Комбинезоны',
            href: '/catalog/zhenskoe/odezhda/kombinezony',
          },
        ],
      },
      {
        items: [
          {
            title: 'Корсеты',
            href: '/catalog/zhenskoe/odezhda/korsety',
          },
          {
            title: 'Костюмы',
            href: '/catalog/zhenskoe/odezhda/kostyumy',
          },
          {
            title: 'Майки и футболки',
            href: '/catalog/zhenskoe/odezhda/majki-i-futbolki',
          },
          {
            title: 'Нижнее белье и домашняя одежда',
            href: '/catalog/zhenskoe/odezhda/nizhnee-bele-i-domashnyaya-odezhda',
          },
          {
            title: 'Платья',
            href: '/catalog/zhenskoe/odezhda/platya',
          },
          {
            title: 'Пляжная одежда',
            href: '/catalog/zhenskoe/odezhda/plyazhnaya-odezhda',
          },
          {
            title: 'Рубашки и блузки',
            href: '/catalog/zhenskoe/odezhda/rubashki-i-bluzki',
          },
        ],
      },
      {
        href: '/catalog/zhenskoe/odezhda',
        items: [
          {
            title: 'Спортивная одежда',
            href: '/catalog/zhenskoe/odezhda/sportivnaya-odezhda',
          },
          {
            title: 'Худи и толстовки',
            href: '/catalog/zhenskoe/odezhda/hudi-i-tolstovki',
          },
          {
            title: 'Шорты',
            href: '/catalog/zhenskoe/odezhda/shorty',
          },
          {
            title: 'Юбки',
            href: '/catalog/zhenskoe/odezhda/yubki',
          },
        ],
      },
    ],
  },
  {
    title: 'Сумки',
    href: '/catalog/zhenskoe/sumki',
    items: [
      {
        title: 'Сумки',
        items: [
          {
            title: 'Аксессуары для сумок',
            href: '/catalog/zhenskoe/sumki/aksessuary-dlya-sumok',
          },
          {
            title: 'Дорожные и спортивные сумки',
            href: '/catalog/zhenskoe/sumki/dorozhnye-i-sportivnye-sumki',
          },
          {
            title: 'Клатчи и вечерние сумки',
            href: '/catalog/zhenskoe/sumki/klatchi-i-vechernie-sumki',
          },
          {
            title: 'Пляжные сумки',
            href: '/catalog/zhenskoe/sumki/plyazhnye-sumki',
          },
          {
            title: 'Поясные сумки',
            href: '/catalog/zhenskoe/sumki/poyasnye-sumki',
          },
          {
            title: 'Рюкзаки',
            href: '/catalog/zhenskoe/sumki/ryukzaki',
          },
          {
            title: 'Сумки с короткими ручками',
            href: '/catalog/zhenskoe/sumki/sumki-s-korotkimi-ruchkami',
          },
        ],
      },
      {
        href: '/catalog/zhenskoe/sumki',
        items: [
          {
            title: 'Сумки тоут',
            href: '/catalog/zhenskoe/sumki/sumki-tout',
          },
          {
            title: 'Сумки через плечо',
            href: '/catalog/zhenskoe/sumki/sumki-cherez-plecho',
          },
        ],
      },
    ],
  },
  {
    title: 'Украшения',
    href: '/catalog/zhenskoe/ukrasheniya',
    items: [
      {
        title: 'Украшения',
        href: '/catalog/zhenskoe/ukrasheniya',
        items: [
          {
            title: 'Булавки и броши',
            href: '/catalog/zhenskoe/aksessuary/ukrasheniya/bulavki-i-broshi',
          },
          {
            title: 'Другое',
            href: '/catalog/zhenskoe/aksessuary/ukrasheniya/drugoe',
          },
          {
            title: 'Запонки',
            href: '/catalog/zhenskoe/ukrasheniya/zaponki',
          },
          {
            title: 'Клипсы',
            href: '/catalog/zhenskoe/ukrasheniya/klipsy',
          },
          {
            title: 'Кольца',
            href: '/catalog/zhenskoe/aksessuary/ukrasheniya/kolca',
          },
          {
            title: 'Комплекты',
            href: '/catalog/zhenskoe/ukrasheniya/komplekty',
          },
          {
            title: 'Цепочки и подвески',
            href: '/catalog/zhenskoe/ukrasheniya/tsepochki-i-podveski',
          },
          {
            title: 'Часы',
            href: '/catalog/zhenskoe/ukrasheniya/chasy',
          },
        ],
      },
    ],
  },
  {
    title: 'Ювелирные изделия',
    href: '/catalog/zhenskoe/yuvelirnye-izdeliya',
    items: [
      {
        title: 'Ювелирные изделия',
        href: '/catalog/zhenskoe/yuvelirnye-izdeliya',
        items: [
          {
            title: 'Браслеты',
            href: '/catalog/zhenskoe/yuvelirnye-izdeliya/bracelety',
          },
          {
            title: 'Броши',
            href: '/catalog/zhenskoe/yuvelirnye-izdeliya/broshi',
          },
          {
            title: 'Запонки',
            href: '/catalog/zhenskoe/yuvelirnye-izdeliya/zaponki',
          },
          {
            title: 'Кольца',
            href: '/catalog/zhenskoe/yuvelirnye-izdeliya/colca',
          },
          {
            title: 'Комплекты',
            href: '/catalog/zhenskoe/yuvelirnye-izdeliya/komplekty',
          },
          {
            title: 'Серьги',
            href: '/catalog/zhenskoe/yuvelirnye-izdeliya/sergi',
          },
          {
            title: 'Цепочки и подвески',
            href: '/catalog/zhenskoe/yuvelirnye-izdeliya/tsepochki-i-podveski',
          },
          {
            title: 'Часы',
            href: '/catalog/zhenskoe/yuvelirnye-izdeliya/tchasy',
          },
        ],
      },
    ],
  },
];
