import { women } from '~/data/menu/women';
import { men } from '~/data/menu/men';
import { kids } from '~/data/menu/kids';
import { lifestyle } from '~/data/menu/lifestyle';
import { womenEN } from '~/data/menu/women-en';
import { menEN } from '~/data/menu/men-en';
import { kidsEN } from '~/data/menu/kids-en';
import { lifestyleEN } from '~/data/menu/lifestyle-en';

export const menuRU = {
  women,
  men,
  kids,
  lifestyle,
};

export const menuEN = {
  women: womenEN,
  men: menEN,
  kids: kidsEN,
  lifestyle: lifestyleEN,
};
