<template>
  <UikitDialog
    :model-value="modelValue"
    position="right"
    full-height
    full-height-no-scroll
    :no-shadow="!isDesktop"
    :hide-backdrop="!isDesktop"
    no-padding
    hide-close
    content-class="osk-global-dialog osk-global-dialog_second osk-auth-choose-acc-dialog"
    @update:model-value="updateModelValue"
  >
    <UikitIcon
      name="ArrowBack"
      size="l"
      class="osk-dialog__close"
      @click="emits('update:modelValue', false)"
    />

    <a
      class="osk-global-dialog__phone"
      href="tel:+78007075308"
      @click="handlePhone"
    >
      <UikitIcon
        name="Phone"
        size="l"
      />
    </a>

    <div
      :class="{
        'osk-auth-choose-acc-dialog__header': true,
        'osk-auth-choose-acc-dialog__header_small': (savedAccounts?.length ?? 0) < 4,
      }"
    >
      <div class="osk-global-dialog__title">
        {{ $t('auth.title') }}
      </div>

      <p class="osk-global-dialog__p">
        {{ $t('auth.selectAccount') }}
      </p>
    </div>

    <ul class="osk-auth-choose-acc-dialog__list">
      <li
        class="osk-auth-choose-acc-dialog__item"
        @click="openLoginDialog"
      >
        <div class="osk-auth-choose-acc-dialog__item-icon">
          <UikitIcon
            name="Plus"
            size="l"
            color="grey"
          />
        </div>

        {{ $t('auth.changeAccount') }}
      </li>

      <li
        v-for="(user, index) in savedAccounts"
        :key="index"
        class="osk-auth-choose-acc-dialog__item"
        @click="openPassDialog(user)"
      >
        <vue-load-image
          v-if="user.avatarPath"
          class="osk-auth-choose-acc-dialog__item-avatar"
        >
          <template #image>
            <img
              :src="user.avatarPath"
              :alt="user.nickname"
            >
          </template>

          <template #preloader>
            <UikitLoaderInner
              class="osk-auth-choose-acc-dialog__item-avatar-loader"
              small
            />
          </template>

          <template #error>
            <span class="osk-auth-choose-acc-dialog__item-avatar_no-image">
              {{ user.nickname?.slice(0, 1) ?? 'U' }}
            </span>
          </template>
        </vue-load-image>

        <div
          v-else
          class="osk-auth-choose-acc-dialog__item-avatar osk-auth-choose-acc-dialog__item-avatar_no-image"
        >
          {{ user.nickname?.slice(0, 1) ?? 'U' }}
        </div>

        <div class="osk-auth-choose-acc-dialog__item-content">
          <div class="osk-auth-choose-acc-dialog__item-name">
            {{ user.nickname }}
          </div>

          <div class="osk-auth-choose-acc-dialog__item-email">
            {{ user.email }}
          </div>
        </div>
      </li>
    </ul>

    <template #dialogs>
      <AuthLoginDialog
        v-model="loginDialog"
        :z-index="1102"
        @global-close="handleClose"
      />

      <AuthEnterPasswordDialog
        v-model="authEnterPasswordDialog.show"
        :user="authEnterPasswordDialog.user"
        :z-index="1102"
        @global-close="handleClose"
      />
    </template>
  </UikitDialog>
</template>

<script setup lang="ts">
import VueLoadImage from 'vue-load-image';

import type { ISavedAccount } from '@/composables/store/useAccountStore';

import { AnalyticsEvents } from '~/types/analytics';

interface IPassDialog {
  show: boolean
  user: ISavedAccount | null
}

const props = defineProps<{
  modelValue: boolean
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
  (e: 'success'): void,
  (e: 'globalClose'): void,
}>();

const page = 'account_selection';

const loginDialog = ref(false);
const authEnterPasswordDialog = ref<IPassDialog>({
  show: false,
  user: null,
});

const { $addEvent } = useNuxtApp();
const { isDesktop } = useUiBreakpoints();
const { savedAccounts, authDialogChapter } = useAccountStore();

function openLoginDialog() {
  loginDialog.value = true;

  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'change_account',
  });
}

function handleClose() {
  emits('update:modelValue', false);
  emits('globalClose');
}

function handlePhone() {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'contact_mobile',
  });
}

function updateModelValue(value: boolean) {
  emits('update:modelValue', value);
}

function openPassDialog(user: ISavedAccount) {
  authEnterPasswordDialog.value = {
    show: true,
    user,
  };

  $addEvent(AnalyticsEvents.ClickEvent, {
    page,
    chapter: authDialogChapter.value,
    action: 'user',
    id_item: user.id,
  });
}

watch(authEnterPasswordDialog, (value) => {
  if (!value) {
    authEnterPasswordDialog.value.user = null;
  }
});

watch(() => props.modelValue, async (value) => {
  if (value) {
    $addEvent(AnalyticsEvents.BlockView, {
      page,
      chapter: authDialogChapter.value,
    });
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-auth-choose-acc-dialog {
  &.osk-dialog__body {
    overflow-y: visible;
    padding-left: 0;
    padding-right: 0;
    padding-top: 54px;

    @include media-query(lg-and-up) {
      padding-left: 0;
      padding-right: 0;
      padding-top: 94px;
    }
  }

  &__header {
    padding: 0 16px;

    @include media-query(lg-and-up) {
      padding: 0 52px;
    }

    &_small {
      @include media-query(lg-and-up) {
        margin-top: -94px;

        @media screen and (max-height: 550px) {
          margin-top: 0;
        }
      }
    }
  }

  .osk-global-dialog__p {
    margin-bottom: 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    overflow-y: auto;

    padding: 0 16px;

    @include media-query(lg-and-up) {
      padding: 0 52px;
    }
  }

  &__item {
    height: 72px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    cursor: pointer;
    transition: all linear .2s;

    &:not(:last-child) {
      border-bottom: 1px solid $grey-3;
    }

    @include media-query(lg-and-up) {
      &:hover {
        color: $grey;
      }
    }
  }

  &__item-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    background: $grey-4;
    border-radius: 100%;
    overflow: hidden;
  }

  &__item-avatar {
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
    font-size: $font-size-subheading;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;

    &_no-image {
      background-color: $black;
      display: flex;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
    }
  }

  &__item-name {
    margin-bottom: 4px;
    font-weight: 600;
  }

  &__item-email {
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
    color: $grey;
  }

  &__item-avatar-loader {
    background: $grey-4;
  }
}
</style>
