<template>
  <div
    v-if="currentCountry"
    class="osk-header-country"
    @click="handleClick"
  >
    <div class="osk-header-country__left">
      <img
        class="osk-header-country__icon"
        :src="currentCountry.imageUrl"
        :alt="currentCountry.name"
        :title="currentCountry.name"
      >

      <div class="osk-header-country__info">
        <div class="osk-header-country__name">
          <b>{{ currentCountry.name }}</b>
          <span>&nbsp;{{ currentCountry.currency?.isoCode }}</span>
        </div>
        <div class="osk-header-country__text">
          {{ $t('components.header.headerMobileCountry') }}
        </div>
      </div>
    </div>
    <div class="osk-header-country__right">
      <UikitIcon name="ArrowBack" />
    </div>
  </div>
</template>

<script setup lang="ts">
const { currentCountry, isCountryDialogOpen } = useInternational();

function handleClick() {
  isCountryDialogOpen.value = true;
}
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-header-country {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: $grey-4;

  &__left {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__right {
    transform: rotate(-90deg);
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__name {
    margin-bottom: 4px;
  }
}
</style>
