import { ICategoryMenu } from '~/types/menu';

export const men: ICategoryMenu[] = [
  {
    title: 'Выбор Oskelly',
    href: '/catalog/muzhskoe?oskellyChoice=true',
    items: [
      {
        title: 'Аксессуары',
        href: '/catalog/muzhskoe/aksessuary?oskellyChoice=true',
        items: [
          {
            title: 'Головные уборы',
            href: '/catalog/muzhskoe/aksessuary/golovnye-ubory?oskellyChoice=true',
          },
          {
            title: 'Кошельки и кардхолдеры',
            href: '/catalog/muzhskoe/aksessuary/koshelki-i-kardholdery?oskellyChoice=true',
          },
          {
            title: 'Очки',
            href: '/catalog/muzhskoe/aksessuary/ochki?oskellyChoice=true',
          },
          {
            title: 'Ремни',
            href: '/catalog/muzhskoe/aksessuary/remni?oskellyChoice=true',
          },
          {
            title: 'Украшения',
            href: '/catalog/muzhskoe/aksessuary/ukrasheniya?oskellyChoice=true',
          },
          {
            title: 'Часы',
            href: '/catalog/muzhskoe/aksessuary/chasy?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Бьюти',
        href: '/catalog/muzhskoe/beauty?oskellyChoice=true',
      },
      {
        title: 'Обувь',
        href: '/catalog/muzhskoe/obuv?oskellyChoice=true',
        items: [
          {
            title: 'Ботинки',
            href: '/catalog/muzhskoe/obuv/botinki?oskellyChoice=true',
          },
          {
            title: 'Кроссовки и кеды',
            href: '/catalog/muzhskoe/obuv/krossovki-i-kedy?oskellyChoice=true',
          },
          {
            title: 'Лоферы и мокасины',
            href: '/catalog/muzhskoe/obuv/lofery-i-mokasiny?oskellyChoice=true',
          },
          {
            title: 'Сандалии и шлепанцы',
            href: '/catalog/muzhskoe/obuv/sandalii-i-shlepanci?oskellyChoice=true',
          },
          {
            title: 'Туфли',
            href: '/catalog/muzhskoe/obuv/tufli?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Одежда',
        href: '/catalog/muzhskoe/odezhda?oskellyChoice=true',
        items: [
          {
            title: 'Брюки и шорты',
            href: '/catalog/muzhskoe/odezhda/bryuki-i-shorty?oskellyChoice=true',
          },
          {
            title: 'Верхняя одежда',
            href: '/catalog/muzhskoe/odezhda/verhnyaya-odezhda?oskellyChoice=true',
          },
          {
            title: 'Костюмы',
            href: '/catalog/muzhskoe/odezhda/kostyumy?oskellyChoice=true',
          },
          {
            title: 'Майки и футболки',
            href: '/catalog/muzhskoe/odezhda/majki-i-futbolki?oskellyChoice=true',
          },
          {
            title: 'Пиджаки',
            href: '/catalog/muzhskoe/odezhda/pidzhaki?oskellyChoice=true',
          },
          {
            title: 'Рубашки',
            href: '/catalog/muzhskoe/odezhda/rubashki?oskellyChoice=true',
          },
          {
            title: 'Худи и толстовки',
            href: '/catalog/muzhskoe/odezhda/hudi-i-tolstovki?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Сумки',
        href: '/catalog/muzhskoe/sumki?oskellyChoice=true',
        items: [
          {
            title: 'Портфели',
            href: '/catalog/muzhskoe/sumki/portfeli?oskellyChoice=true',
          },
          {
            title: 'Сумки на плечо',
            href: '/catalog/muzhskoe/sumki/sumki-na-plecho?oskellyChoice=true',
          },
        ],
      },
    ],
  },
  {
    title: 'Новинки',
    href: '/catalog/muzhskoe',
    items: [
      {
        title: 'Аксессуары',
        href: '/catalog/muzhskoe/aksessuary',
        items: [
          {
            title: 'Аксессуары для смартфона',
            href: '/catalog/muzhskoe/aksessuary/aksessuary-dlya-smartfona',
          },
          {
            title: 'Брелоки',
            href: '/catalog/muzhskoe/aksessuary/breloki',
          },
          {
            title: 'Визитницы',
            href: '/catalog/muzhskoe/aksessuary/vizitnicy',
          },
          {
            title: 'Галстуки и бабочки',
            href: '/catalog/muzhskoe/aksessuary/galstuki-i-babochki',
          },
          {
            title: 'Головные уборы',
            href: '/catalog/muzhskoe/aksessuary/golovnye-ubory',
          },
          {
            title: 'Ключницы',
            href: '/catalog/muzhskoe/aksessuary/klyuchnitsy',
          },
          {
            title: 'Кошельки и кардхолдеры',
            href: '/catalog/muzhskoe/aksessuary/koshelki-i-kardholdery',
          },
        ],
      },
      {
        title: 'Бьюти',
        href: '/catalog/muzhskoe/beauty',
        items: [
          {
            title: 'Аксессуары',
            href: '/catalog/muzhskoe/beauty/aksessuary',
          },
          {
            title: 'Барбершоп',
            href: '/catalog/muzhskoe/beauty/barbershop',
          },
          {
            title: 'Дезодоранты',
            href: '/catalog/muzhskoe/beauty/dezodoranty',
          },
          {
            title: 'Парфюмерия',
            href: '/catalog/muzhskoe/beauty/parfyumeriya',
          },
        ],
      },
      {
        title: 'Обувь',
        href: '/catalog/muzhskoe/obuv',
        items: [
          {
            title: 'Ботинки',
            href: '/catalog/muzhskoe/obuv/botinki',
          },
          {
            title: 'Кроссовки и кеды',
            href: '/catalog/muzhskoe/obuv/krossovki-i-kedy',
          },
          {
            title: 'Лоферы и мокасины',
            href: '/catalog/muzhskoe/obuv/lofery-i-mokasiny',
          },
          {
            title: 'Сандалии и шлепанцы',
            href: '/catalog/muzhskoe/obuv/sandalii-i-shlepanci',
          },
          {
            title: 'Туфли',
            href: '/catalog/muzhskoe/obuv/tufli',
          },
          {
            title: 'Эспадрильи',
            href: '/catalog/muzhskoe/obuv/espadrili',
          },
        ],
      },
      {
        title: 'Одежда',
        href: '/catalog/muzhskoe/odezhda',
        items: [
          {
            title: 'Брюки и шорты',
            href: '/catalog/muzhskoe/odezhda/bryuki-i-shorty',
          },
          {
            title: 'Верхняя одежда',
            href: '/catalog/muzhskoe/odezhda/verhnyaya-odezhda',
          },
          {
            title: 'Джинсы',
            href: '/catalog/muzhskoe/odezhda/dzhinsy',
          },
          {
            title: 'Костюмы',
            href: '/catalog/muzhskoe/odezhda/kostyumy',
          },
          {
            title: 'Майки и футболки',
            href: '/catalog/muzhskoe/odezhda/majki-i-futbolki',
          },
          {
            title: 'Нижнее белье и домашняя одежда',
            href: '/catalog/muzhskoe/nizhnee-bele-i-domashnyaya-odezhda',
          },
          {
            title: 'Пиджаки',
            href: '/catalog/muzhskoe/odezhda/pidzhaki',
          },
        ],
      },
      {
        title: 'Сумки',
        href: '/catalog/muzhskoe/sumki',
        items: [
          {
            title: 'Барсетки',
            href: '/catalog/muzhskoe/sumki/barsetki',
          },
          {
            title: 'Папки для документов',
            href: '/catalog/muzhskoe/sumki/papki-dlya-dokumentov',
          },
          {
            title: 'Портфели',
            href: '/catalog/muzhskoe/sumki/portfeli',
          },
          {
            title: 'Поясные сумки',
            href: '/catalog/muzhskoe/sumki/poyasnye-sumki',
          },
          {
            title: 'Рюкзаки',
            href: '/catalog/muzhskoe/sumki/ryukzaki',
          },
          {
            title: 'Спортивные и дорожные сумки',
            href: '/catalog/muzhskoe/sumki/sportivnye-i-dorozhnye-sumki',
          },
          {
            title: 'Сумки Тоут',
            href: '/catalog/muzhskoe/sumki/sumki-tout',
          },
        ],
      },
    ],
  },
  {
    title: 'Бренды',
    href: '/brands/muzhskoe',
    items: [
      {
        title: 'Бренды',
        items: [
          {
            title: 'ADIDAS YEEZY',
            href: '/catalog/adidasyeezy/muzhskoe',
          },
          {
            title: 'BRIONI',
            href: '/catalog/brioni/muzhskoe',
          },
          {
            title: 'BRUNELLO CUCINELLI',
            href: '/catalog/brunellocucinelli/muzhskoe',
          },
          {
            title: 'DOLCE&GABBANA',
            href: '/catalog/dolcegabbana/muzhskoe',
          },
          {
            title: 'DSQUARED2',
            href: '/catalog/dsquared2/muzhskoe',
          },
          {
            title: 'EMPORIO ARMANI',
            href: '/catalog/emporioarmani/muzhskoe',
          },
          {
            title: 'GUCCI',
            href: '/catalog/gucci/muzhskoe',
          },
          {
            title: 'HUGO BOSS',
            href: '/catalog/hugoboss/muzhskoe',
          },
        ],
      },
      {
        href: '/brands/muzhskoe',
        items: [
          {
            title: 'JOHN RICHMOND',
            href: '/catalog/johnrichmond/muzhskoe',
          },
          {
            title: 'LOUIS VUITTON',
            href: '/catalog/louis-vuitton/muzhskoe',
          },
          {
            title: 'PHILIPP PLEIN',
            href: '/catalog/philippplein/muzhskoe',
          },
          {
            title: 'PRADA',
            href: '/catalog/prada/muzhskoe',
          },
          {
            title: 'TOM FORD',
            href: '/catalog/tom-ford/muzhskoe',
          },
          {
            title: 'ZILLI',
            href: '/catalog/zilli/muzhskoe',
          },
        ],
      },
    ],
  },
  {
    title: 'Аксессуары',
    href: '/catalog/muzhskoe/aksessuary',
    items: [
      {
        title: 'Аксессуары',
        items: [
          {
            title: 'Аксессуары для смартфона',
            href: '/catalog/muzhskoe/aksessuary/aksessuary-dlya-smartfona',
          },
          {
            title: 'Брелоки',
            href: '/catalog/muzhskoe/aksessuary/breloki',
          },
          {
            title: 'Визитницы',
            href: '/catalog/muzhskoe/aksessuary/vizitnicy',
          },
          {
            title: 'Галстуки и бабочки',
            href: '/catalog/muzhskoe/aksessuary/galstuki-i-babochki',
          },
          {
            title: 'Головные уборы',
            href: '/catalog/muzhskoe/aksessuary/golovnye-ubory',
          },
          {
            title: 'Ключницы',
            href: '/catalog/muzhskoe/aksessuary/klyuchnitsy',
          },
          {
            title: 'Кошельки и кардхолдеры',
            href: '/catalog/muzhskoe/aksessuary/koshelki-i-kardholdery',
          },
        ],
      },
      {
        href: '/catalog/muzhskoe/aksessuary',
        items: [
          {
            title: 'Очки',
            href: '/catalog/muzhskoe/aksessuary/ochki',
          },
          {
            title: 'Перчатки',
            href: '/catalog/muzhskoe/aksessuary/perchatki',
          },
          {
            title: 'Ремни',
            href: '/catalog/muzhskoe/aksessuary/remni',
          },
          {
            title: 'Ручки',
            href: '/catalog/muzhskoe/aksessuary/ruchki',
          },
          {
            title: 'Украшения',
            href: '/catalog/muzhskoe/aksessuary/ukrasheniya',
          },
          {
            title: 'Часы',
            href: '/catalog/muzhskoe/aksessuary/chasy',
          },
          {
            title: 'Шарфы',
            href: '/catalog/muzhskoe/aksessuary/sharfy',
          },
        ],
      },
    ],
  },
  {
    title: 'Бьюти',
    href: '/catalog/muzhskoe/beauty',
    items: [
      {
        title: 'Бьюти',
        href: '/catalog/muzhskoe/beauty',
        items: [
          {
            title: 'Аксессуары',
            href: '/catalog/muzhskoe/beauty/aksessuary',
          },
          {
            title: 'Барбершоп',
            href: '/catalog/muzhskoe/beauty/barbershop',
          },
          {
            title: 'Дезодоранты',
            href: '/catalog/muzhskoe/beauty/dezodoranty',
          },
          {
            title: 'Парфюмерия',
            href: '/catalog/muzhskoe/beauty/parfyumeriya',
          },
        ],
      },
    ],
  },
  {
    title: 'Обувь',
    href: '/catalog/muzhskoe/obuv',
    items: [
      {
        title: 'Обувь',
        href: '/catalog/muzhskoe/obuv',
        items: [
          {
            title: 'Ботинки',
            href: '/catalog/muzhskoe/obuv/botinki',
          },
          {
            title: 'Кроссовки и кеды',
            href: '/catalog/muzhskoe/obuv/krossovki-i-kedy',
          },
          {
            title: 'Лоферы и мокасины',
            href: '/catalog/muzhskoe/obuv/lofery-i-mokasiny',
          },
          {
            title: 'Сандалии и шлепанцы',
            href: '/catalog/muzhskoe/obuv/sandalii-i-shlepanci',
          },
          {
            title: 'Туфли',
            href: '/catalog/muzhskoe/obuv/tufli',
          },
          {
            title: 'Эспадрильи',
            href: '/catalog/muzhskoe/obuv/espadrili',
          },
        ],
      },
    ],
  },
  {
    title: 'Одежда',
    href: '/catalog/muzhskoe/odezhda',
    items: [
      {
        title: 'Одежда',
        items: [
          {
            title: 'Брюки и шорты',
            href: '/catalog/muzhskoe/odezhda/bryuki-i-shorty',
          },
          {
            title: 'Верхняя одежда',
            href: '/catalog/muzhskoe/odezhda/verhnyaya-odezhda',
          },
          {
            title: 'Джинсы',
            href: '/catalog/muzhskoe/odezhda/dzhinsy',
          },
          {
            title: 'Костюмы',
            href: '/catalog/muzhskoe/odezhda/kostyumy',
          },
          {
            title: 'Майки и футболки',
            href: '/catalog/muzhskoe/odezhda/majki-i-futbolki',
          },
          {
            title: 'Нижнее белье и домашняя одежда',
            href: '/catalog/muzhskoe/nizhnee-bele-i-domashnyaya-odezhda',
          },
          {
            title: 'Пиджаки',
            href: '/catalog/muzhskoe/odezhda/pidzhaki',
          },
        ],
      },
      {
        href: '/catalog/muzhskoe/odezhda',
        items: [
          {
            title: 'Пляжная одежда',
            href: '/catalog/muzhskoe/odezhda/plyazhnaya-odezhda',
          },
          {
            title: 'Рубашки',
            href: '/catalog/muzhskoe/odezhda/rubashki',
          },
          {
            title: 'Спортивная одежда',
            href: '/catalog/muzhskoe/odezhda/sportivnaya-odezhda',
          },
          {
            title: 'Трикотаж',
            href: '/catalog/muzhskoe/odezhda/trikotazh',
          },
          {
            title: 'Худи и толстовки',
            href: '/catalog/muzhskoe/odezhda/hudi-i-tolstovki',
          },
        ],
      },
    ],
  },
  {
    title: 'Сумки',
    href: '/catalog/muzhskoe/sumki',
    items: [
      {
        title: 'Сумки',
        items: [
          {
            title: 'Барсетки',
            href: '/catalog/muzhskoe/sumki/barsetki',
          },
          {
            title: 'Папки для документов',
            href: '/catalog/muzhskoe/sumki/papki-dlya-dokumentov',
          },
          {
            title: 'Портфели',
            href: '/catalog/muzhskoe/sumki/portfeli',
          },
          {
            title: 'Поясные сумки',
            href: '/catalog/muzhskoe/sumki/poyasnye-sumki',
          },
          {
            title: 'Рюкзаки',
            href: '/catalog/muzhskoe/sumki/ryukzaki',
          },
          {
            title: 'Спортивные и дорожные сумки',
            href: '/catalog/muzhskoe/sumki/sportivnye-i-dorozhnye-sumki',
          },
          {
            title: 'Сумки Тоут',
            href: '/catalog/muzhskoe/sumki/sumki-tout',
          },
        ],
      },
      {
        href: '/catalog/muzhskoe/sumki',
        items: [
          {
            title: 'Сумки на плечо',
            href: '/catalog/muzhskoe/sumki/sumki-na-plecho',
          },
        ],
      },
    ],
  },
  {
    title: 'Ювелирные изделия',
    href: '/catalog/muzhskoe/yuvelirnye-izdeliya',
    items: [
      {
        title: 'Ювелирные изделия',
        href: '/catalog/muzhskoe/yuvelirnye-izdeliya',
        items: [
          {
            title: 'Браслеты',
            href: '/catalog/muzhskoe/yuvelirnye-izdeliya/bracelety',
          },
          {
            title: 'Запонки',
            href: '/catalog/muzhskoe/yuvelirnye-izdeliya/zaponki',
          },
          {
            title: 'Кольца',
            href: '/catalog/muzhskoe/yuvelirnye-izdeliya/koltsa',
          },
          {
            title: 'Серьги',
            href: '/catalog/muzhskoe/yuvelirnye-izdeliya/sergi',
          },
          {
            title: 'Цепочки и подвески',
            href: '/catalog/muzhskoe/yuvelirnye-izdeliya/tsepochki-i-podveski',
          },
          {
            title: 'Часы',
            href: '/catalog/muzhskoe/yuvelirnye-izdeliya/tchasy',
          },
        ],
      },
    ],
  },
];
