<template>
  <div class="osk-header-search">
    <div
      :class="{
        'osk-header-search__form': true,
        'osk-header-search__form_large': isHeaderSearchFull,
      }"
    >
      <UikitFormInput
        v-model.trim="headerSearchQuery"
        name="query"
        size="m"
        button-right-icon="Search"
        :placeholder="$t('components.header.headerSearch.placeholder')"
        required
        no-error-message
        :clearable="isHeaderSearchFull"
        :loading="historyPending"
        @focus="handleFocus"
        @keyup.enter="handleEnter"
        @clear="handleClear"
      />
    </div>

    <Transition name="fade">
      <div
        v-if="isHeaderSearchFull"
        class="osk-header-search__dialog"
      >
        <div class="osk-wrapper">
          <HeaderSearchResults />

          <UikitIcon
            name="Cross"
            size="l"
            class="osk-header-search__dialog-close"
            @click="isHeaderSearchFull = false"
          />
        </div>
      </div>
    </Transition>

    <transition name="fade">
      <div
        v-if="isHeaderSearchFull"
        class="osk-header-search__overlay"
        @click="isHeaderSearchFull = false"
      />
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { AnalyticsEvents } from '~/types/analytics';

const { $addEvent } = useNuxtApp();

const {
  headerSearchQuery,
  historyPending,
  isHeaderSearchFull,
  isResults,
  isResultLoading,
  resultProducts,
  resultUsers,
  handleProductClick,
  handleUserClick,
  globalSearchId,
  usageSearchId,
} = useHeaderSearch();

const { preventBodyScroll } = usePreventScroll();
const { isDesktop } = useUiBreakpoints();

function handleFocus() {
  isHeaderSearchFull.value = true;
}

function handleEnter() {
  if (!isResultLoading.value && isResults.value) {
    if (resultProducts.value?.length) {
      handleProductClick(resultProducts.value[0], 0);
      return;
    }
    if (resultUsers.value?.length) {
      handleUserClick(resultUsers.value[0], 0);
    }
  }
}

function handleClear(value: string) {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page: 'search',
    value,
    id_item: globalSearchId.value,
    action: 'back',
  });
}

watch(isHeaderSearchFull, (value) => {
  if (isDesktop.value) {
    preventBodyScroll(value);
  }

  if (value) {
    $addEvent(AnalyticsEvents.HeaderLinkClick, {
      item: 'search',
    });

    $addEvent(AnalyticsEvents.BlockView, {
      page: 'search',
    });
  } else if (!usageSearchId.value) {
    $addEvent(AnalyticsEvents.ClickEvent, {
      page: 'search',
      value: headerSearchQuery.value,
      id_item: globalSearchId.value,
      action: 'close',
    });
  }
});
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

$search-width: 576px;

.osk-header-search {
  &__dialog-close {
    position: absolute;
    right: 44px;
    top: 44px;
    cursor: pointer;
  }

  &__form {
    width: 269px;
    transition: all linear .2s;
    position: absolute;
    right: 0;
    z-index: 2;
    background: #fff;

    &_large {
      width: $search-width;
      position: absolute;
      right: 50%;
      margin-right: -288px;
    }

    :deep(.osk-field__inner) {
      border: 1px solid $black;
    }
  }

  &__dialog {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    background: $white;
    z-index: 1;
    padding-top: 152px;
    padding-bottom: 52px;
    display: flex;
    justify-content: center;
  }

  &__overlay {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.6);
    overflow: hidden;
    height: 100vh;
  }
}
</style>
