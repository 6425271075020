<template>
  <div
    v-if="currentCountry"
    class="osk-user-country"
    @click="handleClick"
  >
    <img
      class="osk-user-country__icon"
      width="24"
      height="24"
      :src="currentCountry.imageUrl"
      :alt="currentCountry.name"
      :title="currentCountry.name"
    >
  </div>
</template>

<script setup lang="ts">
const { currentCountry, isCountryDialogOpen } = useInternational();

function handleClick() {
  isCountryDialogOpen.value = true;
}
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";
</style>
