<template>
  <UikitDialog
    :model-value="modelValue"
    full-height
    with-mobile-menu
    close-others-on-mobile
    hide-close
    no-shadow
    hide-backdrop
    content-class="osk-catalog-menu-mobile-dialog"
    @update:model-value="emits('update:modelValue', $event)"
  >
    <UikitLoaderInner v-if="pending" />

    <template v-else>
      <UikitTabs
        v-model="activeTab"
        class="osk-catalog-menu-mobile-dialog__tabs"
      >
        <UikitTabsTab
          v-for="(item, index) in catalogData"
          :key="item.item_id ?? index"
          :name="item.item_id ?? String(index)"
          :title="item.name"
          class="osk-catalog-menu-mobile-dialog__tab"
          @click="handleTabClick(item)"
        />
      </UikitTabs>

      <div class="osk-catalog-menu-mobile-dialog__wrapper">
        <div
          v-for="(item, index) in catalogData"
          v-show="activeTab === item.item_id"
          :key="item.item_id ?? index"
          class="osk-catalog-menu-mobile-dialog__item"
        >
          <template
            v-for="(listItem, idx) in ((item.data?.children ?? []).filter((item) => item.type !== 'stream') as ListElement[])"
            :key="`${idx}-listItem`"
          >
            <div
              v-if="listItem.data?.title"
              class="osk-catalog-menu-mobile-dialog__title"
              :class="{'osk-catalog-menu-mobile-dialog__title_first': idx === 0}"
            >
              {{ listItem.data.title }}
            </div>

            <template v-if="listItem.data?.children?.length">
              <template
                v-for="(banner, i) in listItem.data.children"
                :key="`${i}-banner`"
              >
                <CatalogMenuMobileBanner
                  v-if="banner.type === 'banner'"
                  :banner="(banner as BannerElement)"
                  :page="page"
                  @open-catalog-dialog="openCatalogDialog"
                />

                <div
                  v-else-if="banner.type === 'banner_grid'"
                  class="osk-catalog-menu-mobile-dialog__banner-grid"
                >
                  <CatalogMenuMobileBanner
                    v-for="(bannerMini, ind) in (banner as BannerGridElement).data?.children ?? []"
                    :key="`${ind}-bannerMini`"
                    :banner="(bannerMini as BannerElement)"
                    mini
                    :page="page"
                    @open-catalog-dialog="openCatalogDialog"
                  />
                </div>
              </template>
            </template>
          </template>
        </div>
      </div>
    </template>

    <CatalogMenuMobileSecondDialog
      v-model="showSecondCatalogMenu"
      v-model:banner="secondCatalogData"
      :page="page"
    />
  </UikitDialog>
</template>

<script setup lang="ts">
import { useApi } from '~/restAPI';
import { Catalog } from '~/restAPI/Catalog';
import {
  CatalogMenu, ListElement, BannerElement, BannerGridElement, PrimaryPageType, MenuItem,
} from '~/restAPI/data-contracts';
import { AnalyticsEvents } from '~/types/analytics';

const { $addEvent } = useNuxtApp();

const { createApiInstance } = useApi();
const catalogApi = createApiInstance(Catalog);

const props = defineProps<{
  modelValue: boolean
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
}>();

const data = ref<CatalogMenu>();
const activeTab = ref();
const showSecondCatalogMenu = ref(false);
const secondCatalogData = ref<BannerElement>();

const { activeMenuCategory } = useActiveMenuCategory();
const { genderPages } = useGender();

const categoriesIds = {
  [genderPages[PrimaryPageType.FEMALE]]: '1',
  [genderPages[PrimaryPageType.MALE]]: '2',
  [genderPages[PrimaryPageType.CHILD]]: '3',
  [genderPages[PrimaryPageType.LIFESTYLE]]: '4',
};

const { pending, execute: getData } = await useAsyncData(() => (data.value ? Promise.resolve(data.value) : catalogApi.getApiV2CatalogMenuContent({
  version: '4',
  isExperimentalVersion: true,
}).then((res) => {
  data.value = res.data.data;
  return res.data.data;
})), {
  immediate: false,
});

const catalogData = computed(() => data.value?.catalog ?? []);
const page = computed(() => `catalog_main_${activeTab.value}`);

function openCatalogDialog(banner: BannerElement) {
  secondCatalogData.value = banner;
  showSecondCatalogMenu.value = true;
}

function handleTabClick(item: MenuItem) {
  $addEvent(AnalyticsEvents.CatalogMobileView, {
    page: page.value,
  });
}

watch(() => props.modelValue, async (value) => {
  if (value) {
    await getData();

    const defaultData = catalogData.value[0].item_id;

    if (activeMenuCategory.value) {
      const activeCategory = categoriesIds[activeMenuCategory.value];

      activeTab.value = catalogData.value.find((item) => item.item_id === activeCategory)?.item_id ?? defaultData;
    } else {
      activeTab.value = defaultData;
    }

    $addEvent(AnalyticsEvents.CatalogMobileView, {
      page: page.value,
    });
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-catalog-menu-mobile-dialog {
  @include font-body-all;
  overflow: visible !important;
  display: flex;
  flex-direction: column;
  padding-bottom: 0 !important;

  &__tabs {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    margin-top: -16px;
    width: 100%;
    left: 16px;
    background: #fff;
    z-index: 1;
  }

  &__tab {
    font-weight: 500;
    display: flex;
    padding-top: 19px;
  }

  &__wrapper {
    overflow-y: scroll;
    scrollbar-width: none;
    padding-bottom: 4px;
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .osk-tab__title {
    margin: 0;
    padding-top: 0;
    font-weight: 500;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &:before {
      content: attr(data-text);
      content: attr(data-text) / "";
      height: 0;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: 700;

      @media speech {
        display: none;
      }
    }
  }

  &__title {
    margin-top: 34px;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    &_first {
      margin-top: 25px;
    }
  }

  &__banner-grid {
    display: flex;
  }

  &__banner-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    position: absolute;
    left: 12px;
    bottom: 12px;
  }
}
</style>
