<template>
  <UikitDialog
    :model-value="modelValue"
    full-height
    hide-close
    hide-backdrop
    content-class="osk-catalog-menu-mobile-second-dialog"
    @update:model-value="emits('update:modelValue', $event)"
  >
    <div class="osk-catalog-menu-mobile-second-dialog__header">
      <UikitIcon
        name="ArrowBack"
        size="l"
        class="osk-catalog-menu-mobile-second-dialog__header-icon"
        @click="emits('update:modelValue', false)"
      />

      <span class="osk-catalog-menu-mobile-second-dialog__header-title">
        {{ banner?.data?.title }}
      </span>
    </div>

    <div class="osk-catalog-menu-mobile-second-dialog__content">
      <UikitLoaderInner v-if="pending" />

      <ul
        v-else
        class="osk-catalog-menu-mobile-second-dialog__list"
      >
        <li
          v-if="linkAll"
          class="osk-catalog-menu-mobile-second-dialog__item"
        >
          <a
            :href="linkAll"
            class="osk-catalog-menu-mobile-second-dialog__link"
          >
            {{ $t('components.catalog.catalogMenuMobileSecondDialog.all') }}

            <UikitIcon
              name="ArrowBack"
              size="s"
              class="osk-catalog-menu-mobile-second-dialog__link-icon"
            />
          </a>
        </li>

        <li
          v-for="(item, index) in data"
          :key="index"
          class="osk-catalog-menu-mobile-second-dialog__item"
        >
          <a
            :href="`/catalog/${item.url}`"
            class="osk-catalog-menu-mobile-second-dialog__link"
            @click="handleLinkClick(item)"
          >
            {{ item.displayName }}

            <UikitIcon
              name="ArrowBack"
              size="s"
              class="osk-catalog-menu-mobile-second-dialog__link-icon"
            />
          </a>
        </li>
      </ul>
    </div>
  </UikitDialog>
</template>

<script setup lang="ts">
import { useApi } from '~/restAPI';
import { Catalog } from '~/restAPI/Catalog';
import {
  CategoryLink, CategoryDTORes, BannerElement,
} from '~/restAPI/data-contracts';
import { AnalyticsEvents } from '~/types/analytics';

const { $addEvent } = useNuxtApp();

const props = defineProps<{
  modelValue: boolean
  banner?: BannerElement
  page?: string
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
  (e: 'update:banner', value?: CategoryLink): void,
}>();

const { createApiInstance } = useApi();
const catalogApi = createApiInstance(Catalog);

const data = ref<CategoryDTORes[]>();

const link = computed(() => props.banner?.data?.link as CategoryLink);
const categoryId = computed(() => link.value.categoryId);
const linkAll = computed(() => {
  if (!data.value) return '';

  const url = data.value[0].url?.split('/').slice(0, 2).join('/');

  return `/catalog/${url}`;
});

const { pending, execute: getData } = await useAsyncData(() => catalogApi.getDirectChildrenCategoriesUsingGet(categoryId.value!, {
  format: 'json',
})
  .then((res) => {
    data.value = res.data.data;
    return res.data.data;
  }), {
  immediate: false,
});

function handleLinkClick(item: CategoryDTORes) {
  $addEvent(AnalyticsEvents.CatalogMobileLinkClick, {
    id_item: String(item.id),
    item: 'category_2',
    page: props.page,
  });
}

watch(() => props.modelValue, async (value) => {
  if (value) {
    await getData();
  } else {
    data.value = undefined;
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-catalog-menu-mobile-second-dialog {
  display: flex;
  flex-direction: column;

  &__header {
    @include font-style($font-size-subheading, $line-height-subheading, $font-weight-semibold);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: $indent-compact;
    margin-bottom: $indent-small;
  }

  &__header-icon {
    position: absolute;
    left: 0;
  }

  &__content {
    position: relative;
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    &:last-child {
      .osk-catalog-menu-mobile-second-dialog__link {
        border-bottom: 0;
      }
    }
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $indent-medium 0;
    @include font-style($font-size-body, $line-height-body, $font-weight-medium);
    border-bottom: 0.5px solid #DBDBDB;
  }

  &__link-icon {
    transform: rotate(180deg);
  }
}
</style>
