<template>
  <UikitDialog
    :model-value="modelValue"
    full-height
    full-height-no-scroll
    with-mobile-menu
    close-others-on-mobile
    no-shadow
    no-padding
    hide-close
    hide-backdrop
    content-class="osk-header-search-dialog"
    @update:model-value="emits('update:modelValue', $event)"
  >
    <div class="osk-header-search-dialog__wrapper">
      <UikitForm class="osk-header-search-dialog__form">
        <UikitFormInput
          ref="$input"
          v-model.trim="headerSearchQuery"
          class="osk-header-search-dialog__form-input"
          name="query"
          size="m"
          icon-left="Search"
          :placeholder="$t('components.header.headerSearch.placeholder')"
          required
          clearable
          no-error-message
          background="grey-4"
          :loading="historyPending"
          @clear="handleClear"
        />

        <div
          class="osk-header-search-dialog__form-clear"
          @click="handleClose"
        >
          {{ $t('components.header.headerSearchDialog.cancel') }}
        </div>
      </UikitForm>

      <HeaderSearchResults
        class="osk-header-search-dialog__results"
        @click-link="emits('update:modelValue', false)"
      />
    </div>
  </UikitDialog>
</template>

<script setup lang="ts">
import { AnalyticsEvents } from '~/types/analytics';

const props = defineProps<{
  modelValue: boolean
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
}>();

const $input = ref();

const { $addEvent } = useNuxtApp();
const { activeMenuCategory } = useActiveMenuCategory();

const {
  headerSearchQuery,
  activeTab,
  historyPending,
  showResults,
  globalSearchId,
  usageSearchId,
} = useHeaderSearch();

function handleClear(value: string) {
  $addEvent(AnalyticsEvents.ClickEvent, {
    page: 'search',
    value,
    id_item: globalSearchId.value,
    action: 'back',
  });
}

function handleClose() {
  emits('update:modelValue', false);
}

watch(() => props.modelValue, async (value) => {
  if (value) {
    if (activeMenuCategory.value === 'men') {
      activeTab.value = 'man';
    } else if (activeMenuCategory.value === 'women') {
      activeTab.value = 'woman';
    } else {
      activeTab.value = activeMenuCategory.value ?? 'woman';
    }

    showResults();

    setTimeout(() => {
      if ($input.value) {
        $input.value.setFocus();
      }
    }, 0);

    $addEvent(AnalyticsEvents.BlockView, {
      page: 'search',
    });
  } else if (!usageSearchId.value) {
    $addEvent(AnalyticsEvents.ClickEvent, {
      page: 'search',
      value: headerSearchQuery.value,
      id_item: globalSearchId.value,
      action: 'close',
    });
  }
});
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-header-search-dialog {
  @include font-body-all;

  &__wrapper {
    padding: $indent-medium;
    padding-top: $indent-small;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__form {
    margin-bottom: $indent-compact;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__form-input {
    width: 100%;
  }

  &__form-clear {
    @include font-style($font-size-caption, $line-height-body, $font-weight-medium);
    margin-left: $indent-medium;
  }

  &__results {
    height: calc(100% - 33px);
  }

  .osk-header-search-results__history {
    display: flex;
    flex-direction: column;
  }
}
</style>
