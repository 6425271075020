<template>
  <UikitMenuItem
    :active="isItemActive"
    class="osk-menu-item-country"
    @click="handleClick"
  >
    <slot>
      <div class="osk-menu-item-country__inner">
        <img
          class="osk-menu-item-country__flag"
          :src="value.imageUrl"
          :alt="label"
        >
        <div class="osk-menu-item-country__label">
          {{ label }}
        </div>
      </div>
    </slot>
  </UikitMenuItem>
</template>

<script setup lang="ts">
import isEqual from 'lodash.isequal';
import { selectKey } from '~/types/components/select';

const props = defineProps<{
  value: any
  label: string
}>();

const $select = inject(selectKey);

const isItemActive = computed(() => {
  if (!$select?.selectedItems.value?.length) return false;

  return !!$select.selectedItems.value.find((item) => isEqual(item.value, props.value));
});

const item = computed(() => ({
  value: props.value,
  label: props.label,
}));

function handleClick() {
  if (!$select) return;

  $select.handleOptionClick(item.value);
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-menu-item-country {
  font-size: 14px;
  font-weight: 500;
  padding: 12px 16px;
}

.osk-menu-item-country__inner {
  display: flex;
  align-items: center;
  gap: 12px;
}

.osk-menu-item-country__flag {
  width: 24px;
  height: 24px;
}
</style>
