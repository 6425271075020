<template>
  <nuxt-link
    :to="link"
    :external="!link?.includes('products') && !link?.includes('catalog') && !link?.includes('profile')"
    class="osk-notifications-item"
    @click="readNow(item.id!, item.read!)"
  >
    <div
      class="osk-notifications-item__avatar"
    >
      <div
        :class="{
          'osk-notifications-item__avatar-image': true,
          'osk-notifications-item__avatar-image_no-img': !isMainIcon && !item.initiator,
        }"
      >
        <div
          v-if="isMainIcon"
          class="osk-notifications-item__avatar-mainIcon"
        >
          <img
            :src="item.mainIcon"
            alt="mainIcon"
          >
        </div>

        <UikitUserAvatar
          v-if="item.initiator"
          :user="item.initiator"
          size="m"
        />

        <div
          v-if="item.tinyIcon"
          class="osk-notifications-item__avatar-icon"
        >
          <img
            :src="item.tinyIcon"
            alt="tinyIcon"
            class="osk-notifications-item__avatar-icon-image"
          >
        </div>
      </div>

      <div
        v-if="!item.read"
        class="osk-notifications-item__avatar-circle"
      />
    </div>

    <div class="osk-notifications-item__info">
      <div class="osk-notifications-item__info-title">
        {{ title }}
      </div>

      <div class="osk-notifications-item__info-sub">
        {{ subTitle }}
      </div>

      <div class="osk-notifications-item__info-date">
        {{ date }}

        <span
          v-if="isComment"
          class="osk-notifications-item__info-answer"
          @click.stop.prevent="handleAnswer(item)"
        >
          {{ $t('components.notifications.notificationsItem.answer') }}
        </span>
      </div>
    </div>

    <div class="osk-notifications-item__right">
      <UikitButton
        v-if="newFollowingNotifications"
        class="osk-notifications-item__button"
        size="s"
        :outline="isFollowed"
        :loading="followLoading"
        @click.stop.prevent="handleFollow()"
      >
        {{ followedText }}
      </UikitButton>

      <div
        v-else-if="isNoRightImage && item.targetObjectImage"
        class="osk-notifications-item__right-block"
      >
        <div
          v-if="item.targetObjectImageHint"
          class="osk-notifications-item__right-mask"
        >
          {{ item.targetObjectImageHint }}
        </div>

        <img
          class="osk-notifications-item__right-image"
          :src="item.targetObjectImage"
          alt="targetObjectImage"
        >
      </div>
    </div>
  </nuxt-link>
</template>

<script setup lang="ts">
import { NotificationDTO } from '~/restAPI/data-contracts';
import { useAccountService } from '~/providers/account';

const props = defineProps<{
  item: NotificationDTO,
}>();

const emits = defineEmits<{
  (e: 'close'): void,
}>();

const { pathReadNotifications } = useAccountService();

const item = ref(props.item);

const {
  isMainIcon,
  title,
  subTitle,
  date,
  isNoRightImage,
  link,
  newFollowingNotifications,
  isComment,
} = useNotificationItem(item.value);

const {
  followLoading, isFollowed, follow, followedText,
} = useFollowUser(props.item.initiator);

function readNow(id: number, read: boolean) {
  if (!read) {
    pathReadNotifications(id)
      .then((data) => {
        item.value = data!;
      });
  }
}

async function handleFollow() {
  await follow();
}

function handleAnswer(data: NotificationDTO) {
  const commentId = data?.targetObject?.id;

  readNow(data.id!, data.read!);

  navigateTo({
    path: link.value,
    ...(commentId ? { query: { commentId } } : {}),
  });

  emits('close');
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-notifications-item {
  display: flex;
  align-items: flex-start;
  padding: $indent-medium 0;
  transition: all linear .2s;

  @include media-query(lg-and-up) {
    padding: $indent-mmedium 52px;
    margin: 0 -52px;

    &:hover {
      background: $grey-4;
    }
  }

  &__avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: $indent-medium;
    flex-shrink: 0;

    :deep(.osk-user-avatar) {
      margin-right: 0;
    }
  }

  &__avatar-image {
    position: relative;

    &_no-img {
      background: url(~/assets/img/oskelly-default-notification.svg) no-repeat center center #E8E8E8;
      width: 40px;
      height: 40px;
      border-radius: 100px;
    }
  }

  &__avatar-mainIcon {
    border-radius: 100px;
    overflow: hidden;
    width: 40px;
    height: 40px;
  }

  &__avatar-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: -6px;
    right: -6px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    overflow: hidden;
  }

  &__avatar-icon-image {
    width: 20px;
    height: 20px;
  }

  &__avatar-circle {
    width: 6px;
    height: 6px;
    background: $red;
    border-radius: 100px;
    margin-top: 10px;
  }

  &__info {
    width: 100%;
  }

  &__info-title {
    font-weight: $font-weight-semibold;
    margin-bottom: $indent-small;

    @include media-query(lg-and-up) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__info-sub {
    margin-bottom: $indent-small;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    @include media-query(lg-and-up) {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: $indent-xsmall;
    }
  }

  &__info-date {
    color: $grey;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    @include media-query(lg-and-up) {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }

  &__right {
    margin-left: auto;
    flex-shrink: 0;
  }

  &__right-block {
    border-radius: $radius-small;
    overflow: hidden;
    margin-left: $indent-large;
    background: $white;
    @include image-bg-grey;
  }

  &__right-mask {
    @include font-style($font-size-caption, $line-height-caption, $font-weight-medium);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    background: rgba(0,0,0,.5);
  }

  &__right-image {
    width: 60px;
    height: 60px;
    object-fit: contain;

    @include media-query(lg-and-up) {
      width: 64px;
      height: 64px;
    }
  }

  &__button {
    width: 108px;
    height: 31px;
    line-height: 31px;
    font-size: $font-size-caption;
    padding: 0;
    margin-left: $indent-compact;
    cursor: pointer;
  }

  &__info-answer {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: $blue;
    margin-left: 10px;

    @include media-query(lg-and-up) {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
