<template>
  <div class="osk-header-mobile">
    <div class="osk-header-mobile__buttons">
      <a href="tel:+78007075308">
        <UikitIcon
          name="Phone"
          size="l"
        />
      </a>

      <div
        class="osk-header-mobile__search"
        @click="openSearchModal"
      >
        <UikitIcon
          name="Search"
          size="l"
        />
      </div>
    </div>

    <nuxt-link
      to="/"
      class="osk-header-mobile__logo"
    >
      <img
        src="~assets/img/oskelly-logo.svg"
        alt=""
      >
    </nuxt-link>

    <div class="osk-header-mobile__buttons">
      <HeaderWishlist />
      <HeaderCart class="osk-header-mobile__link" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { AnalyticsEvents } from '~/types/analytics';

const { $addEvent } = useNuxtApp();
const { isHeaderSearchMobileDialog } = useHeaderSearch();

const { isDesktop } = useUiBreakpoints();

function openSearchModal() {
  isHeaderSearchMobileDialog.value = !isHeaderSearchMobileDialog.value;

  $addEvent(AnalyticsEvents.HeaderLinkClick, {
    item: 'search',
  });
}

watch(isDesktop, (value) => {
  if (value) {
    isHeaderSearchMobileDialog.value = false;
  }
});
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-header-mobile {
  @include font-body-all;
  background: #fff;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $indent-medium;
  z-index: 1;

  @include media-query(lg-and-up) {
    display: none;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__search {
    margin-left: $indent-medium;
  }

  &__logo {
    width: 120px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__link {
    &:last-child {
      margin-left: $indent-medium;

      &:deep(.osk-header-cart__count) {
        margin-left: -8px;
      }
    }
  }
}
</style>
