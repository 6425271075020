import { ICategoryMenu } from '~/types/menu';

export const womenEN: ICategoryMenu[] = [
  {
    title: 'OSKELLY Favorites',
    href: '/catalog/zhenskoe?oskellyChoice=true',
    items: [
      {
        title: 'Accessories',
        href: '/catalog/zhenskoe/aksessuary?oskellyChoice=true',
        items: [
          {
            title: 'Business Card Holders',
            href: '/catalog/zhenskoe/aksessuary/vizitnicy?oskellyChoice=true',
          },
          {
            title: 'Hats',
            href: '/catalog/zhenskoe/aksessuary/golovnye-ubory?oskellyChoice=true',
          },
          {
            title: 'Wallets and Cardholders',
            href: '/catalog/zhenskoe/aksessuary/koshelki-i-kardholdery?oskellyChoice=true',
          },
          {
            title: 'Rims',
            href: '/catalog/zhenskoe/aksessuary/obodki?oskellyChoice=true',
          },
          {
            title: 'Glasses',
            href: '/catalog/zhenskoe/aksessuary/ochki?oskellyChoice=true',
          },
          {
            title: 'Gloves',
            href: '/catalog/zhenskoe/aksessuary/perchatki?oskellyChoice=true',
          },
          {
            title: 'Headbands',
            href: '/catalog/zhenskoe/aksessuary/povyazki-na-golovu?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Beauty',
        href: '/catalog/zhenskoe/beauty?oskellyChoice=true',
        items: [
          {
            title: 'Fragrance',
            href: '/catalog/zhenskoe/beauty/parfyumeriya?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Shoes',
        href: '/catalog/zhenskoe/obuv?oskellyChoice=true',
        items: [
          {
            title: 'Flip Flops',
            href: '/catalog/zhenskoe/obuv/baletki?oskellyChoice=true',
          },
          {
            title: 'Sandals',
            href: '/catalog/zhenskoe/obuv/bosonozhki?oskellyChoice=true',
          },
          {
            title: 'Ankle Boots',
            href: '/catalog/zhenskoe/obuv/botilony/botilony-i-polusapogi?oskellyChoice=true',
          },
          {
            title: 'Boots',
            href: '/catalog/zhenskoe/obuv/botinki?oskellyChoice=true',
          },
          {
            title: 'Brogues and Oxfords',
            href: '/catalog/zhenskoe/obuv/broggi-i-oksfordy?oskellyChoice=true',
          },
          {
            title: 'Sneakers',
            href: '/catalog/zhenskoe/obuv/krossovki-i-kedy?oskellyChoice=true',
          },
          {
            title: 'Low-heeled Pumps',
            href: '/catalog/zhenskoe/obuv/lodochki-na-nizkom-kabluke?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Clothes',
        href: '/catalog/zhenskoe/odezhda?oskellyChoice=true',
        items: [
          {
            title: 'Pants',
            href: '/catalog/zhenskoe/odezhda/bryuki?oskellyChoice=true',
          },
          {
            title: 'Outerwear',
            href: '/catalog/zhenskoe/odezhda/verhnyaya-odezhda?oskellyChoice=true',
          },
          {
            title: 'Knitwear',
            href: '/catalog/zhenskoe/odezhda/dzhempery-kardigany-trikotazh?oskellyChoice=true',
          },
          {
            title: 'Jeans',
            href: '/catalog/zhenskoe/odezhda/dzhinsy?oskellyChoice=true',
          },
          {
            title: 'Blazers and Jackets',
            href: '/catalog/zhenskoe/odezhda/zhakety-i-pidzhaki?oskellyChoice=true',
          },
          {
            title: 'Suits',
            href: '/catalog/zhenskoe/odezhda/kostyumy?oskellyChoice=true',
          },
          {
            title: 'Tops',
            href: '/catalog/zhenskoe/odezhda/majki-i-futbolki?oskellyChoice=true',
          },
        ],
      },
      {
        title: 'Bags',
        href: '/catalog/zhenskoe/sumki?oskellyChoice=true',
        items: [
          {
            title: 'Travel and Sports Bags',
            href: '/catalog/zhenskoe/sumki/dorozhnye-i-sportivnye-sumki?oskellyChoice=true',
          },
          {
            title: 'Clutches and Pouches',
            href: '/catalog/zhenskoe/sumki/klatchi-i-vechernie-sumki?oskellyChoice=true',
          },
          {
            title: 'Beach Bags',
            href: '/catalog/zhenskoe/sumki/plyazhnye-sumki?oskellyChoice=true',
          },
          {
            title: 'Belt Bags',
            href: '/catalog/zhenskoe/sumki/poyasnye-sumki?oskellyChoice=true',
          },
          {
            title: 'Backpacks',
            href: '/catalog/zhenskoe/sumki/ryukzaki?oskellyChoice=true',
          },
          {
            title: 'Short handle Bags',
            href: '/catalog/zhenskoe/sumki/sumki-s-korotkimi-ruchkami?oskellyChoice=true',
          },
          {
            title: 'Totes',
            href: '/catalog/zhenskoe/sumki/sumki-tout?oskellyChoice=true',
          },
        ],
      },
    ],
  },
  {
    title: 'New Arrivals',
    href: '/catalog/zhenskoe',
    items: [
      {
        title: 'Accessories',
        href: '/catalog/zhenskoe/aksessuary',
        items: [
          {
            title: 'Hair Accessories',
            href: '/catalog/zhenskoe/aksessuary/aksessuary-dlya-volos',
          },
          {
            title: 'Accessories for Equipment',
            href: '/catalog/zhenskoe/aksessuary/aksessuary-dlya-tehniki',
          },
          {
            title: 'Key Rings',
            href: '/catalog/zhenskoe/aksessuary/breloki',
          },
          {
            title: 'Business Card Holders',
            href: '/catalog/zhenskoe/aksessuary/vizitnicy',
          },
          {
            title: 'Hats',
            href: '/catalog/zhenskoe/aksessuary/golovnye-ubory',
          },
          {
            title: 'Umbrellas',
            href: '/catalog/zhenskoe/aksessuary/zonty',
          },
          {
            title: 'Housekeepers',
            href: '/catalog/zhenskoe/aksessuary/klyuchnitsy',
          },
        ],
      },
      {
        title: 'Beauty',
        href: '/catalog/zhenskoe/beauty',
        items: [
          {
            title: 'Gadgets',
            href: '/catalog/zhenskoe/beauty/devaysy',
          },
          {
            title: 'Makeup',
            href: '/catalog/zhenskoe/beauty/makiyazh',
          },
          {
            title: 'Fragrance',
            href: '/catalog/zhenskoe/beauty/parfyumeriya',
          },
          {
            title: 'Hair Care',
            href: '/catalog/zhenskoe/beauty/ukhod-za-volosami',
          },
          {
            title: 'Skinncare',
            href: '/catalog/zhenskoe/beauty/ukhod-za-litsom',
          },
          {
            title: 'Body Care',
            href: '/catalog/zhenskoe/beauty/ukhod-za-telom',
          },
        ],
      },
      {
        title: 'Shoes',
        href: '/catalog/zhenskoe/obuv',
        items: [
          {
            title: 'Flip Flops',
            href: '/catalog/zhenskoe/obuv/baletki',
          },
          {
            title: 'Sandals',
            href: '/catalog/zhenskoe/obuv/bosonozhki',
          },
          {
            title: 'Ankle Boots',
            href: '/catalog/zhenskoe/obuv/botilony/botilony-i-polusapogi',
          },
          {
            title: 'Boots',
            href: '/catalog/zhenskoe/obuv/botinki',
          },
          {
            title: 'Brogues and Oxfords',
            href: '/catalog/zhenskoe/obuv/broggi-i-oksfordy',
          },
          {
            title: 'Sneakers',
            href: '/catalog/zhenskoe/obuv/krossovki-i-kedy',
          },
          {
            title: 'Low-heeled Pumps',
            href: '/catalog/zhenskoe/obuv/lodochki-na-nizkom-kabluke',
          },
        ],
      },
      {
        title: 'Clothes',
        href: '/catalog/zhenskoe/odezhda',
        items: [
          {
            title: 'Pants',
            href: '/catalog/zhenskoe/odezhda/bryuki',
          },
          {
            title: 'Outerwear',
            href: '/catalog/zhenskoe/odezhda/verhnyaya-odezhda',
          },
          {
            title: 'Knitwear',
            href: '/catalog/zhenskoe/odezhda/dzhempery-kardigany-trikotazh',
          },
          {
            title: 'Jeans',
            href: '/catalog/zhenskoe/odezhda/dzhinsy',
          },
          {
            title: 'Blazers and Jackets',
            href: '/catalog/zhenskoe/odezhda/zhakety-i-pidzhaki',
          },
          {
            title: 'Capris',
            href: '/catalog/zhenskoe/odezhda/kapri',
          },
          {
            title: 'Jumpsuits',
            href: '/catalog/zhenskoe/odezhda/kombinezony',
          },
        ],
      },
      {
        title: 'Bags',
        href: '/catalog/zhenskoe/sumki',
        items: [
          {
            title: 'Bag Accessories',
            href: '/catalog/zhenskoe/sumki/aksessuary-dlya-sumok',
          },
          {
            title: 'Travel and Sports Bags',
            href: '/catalog/zhenskoe/sumki/dorozhnye-i-sportivnye-sumki',
          },
          {
            title: 'Clutches and Pouches',
            href: '/catalog/zhenskoe/sumki/klatchi-i-vechernie-sumki',
          },
          {
            title: 'Beach Bags',
            href: '/catalog/zhenskoe/sumki/plyazhnye-sumki',
          },
          {
            title: 'Belt Bags',
            href: '/catalog/zhenskoe/sumki/poyasnye-sumki',
          },
          {
            title: 'Backpacks',
            href: '/catalog/zhenskoe/sumki/ryukzaki',
          },
          {
            title: 'Short handle Bags',
            href: '/catalog/zhenskoe/sumki/sumki-s-korotkimi-ruchkami',
          },
        ],
      },
    ],
  },
  {
    title: 'Brands',
    href: '/brands/zhenskoe',
    items: [
      {
        title: 'Brands',
        items: [
          {
            title: 'BALENCIAGA',
            href: '/catalog/balenciaga/zhenskoe',
          },
          {
            title: 'CELINE',
            href: '/catalog/celine/zhenskoe',
          },
          {
            title: 'CHANEL',
            href: '/catalog/chanel/zhenskoe',
          },
          {
            title: 'CHRISTIAN DIOR',
            href: '/catalog/christian-dior/zhenskoe',
          },
          {
            title: 'CHRISTIAN LOUBOUTIN',
            href: '/catalog/christianlouboutin/zhenskoe',
          },
          {
            title: 'DOLCE&GABBANA',
            href: '/catalog/dolcegabbana/zhenskoe',
          },
          {
            title: 'FENDI',
            href: '/catalog/fendi/zhenskoe',
          },
          {
            title: 'GUCCI',
            href: '/catalog/gucci/zhenskoe',
          },
        ],
      },
      {
        href: '/brands/zhenskoe',
        items: [
          {
            title: 'LOUIS VUITTON',
            href: '/catalog/louis-vuitton/zhenskoe',
          },
          {
            title: 'MICHAEL KORS',
            href: '/catalog/michaelkors/zhenskoe',
          },
          {
            title: 'PINKO',
            href: '/catalog/pinko/zhenskoe',
          },
          {
            title: 'PRADA',
            href: '/catalog/prada/zhenskoe',
          },
          {
            title: 'SAINT LAURENT',
            href: '/catalog/saintlaurent/zhenskoe',
          },
          {
            title: 'VALENTINO',
            href: '/catalog/valentino/zhenskoe',
          },
        ],
      },
    ],
  },
  {
    title: 'Accessories',
    href: '/catalog/zhenskoe/aksessuary',
    items: [
      {
        title: 'Accessories',
        items: [
          {
            title: 'Hair Accessories',
            href: '/catalog/zhenskoe/aksessuary/aksessuary-dlya-volos',
          },
          {
            title: 'Accessories for Equipment',
            href: '/catalog/zhenskoe/aksessuary/aksessuary-dlya-tehniki',
          },
          {
            title: 'Key Rings',
            href: '/catalog/zhenskoe/aksessuary/breloki',
          },
          {
            title: 'Business Card Holders',
            href: '/catalog/zhenskoe/aksessuary/vizitnicy',
          },
          {
            title: 'Hats',
            href: '/catalog/zhenskoe/aksessuary/golovnye-ubory',
          },
          {
            title: 'Umbrellas',
            href: '/catalog/zhenskoe/aksessuary/zonty',
          },
          {
            title: 'Housekeepers',
            href: '/catalog/zhenskoe/aksessuary/klyuchnitsy',
          },
        ],
      },
      {
        items: [
          {
            title: 'Cosmetic Bags',
            href: '/catalog/zhenskoe/aksessuary/kosmetichki',
          },
          {
            title: 'Wallets and Cardholders',
            href: '/catalog/zhenskoe/aksessuary/koshelki-i-kardholdery',
          },
          {
            title: 'Covers and Cases',
            href: '/catalog/zhenskoe/aksessuary/oblozhki-i-futlyary',
          },
          {
            title: 'Rims',
            href: '/catalog/zhenskoe/aksessuary/obodki',
          },
          {
            title: 'Glasses',
            href: '/catalog/zhenskoe/aksessuary/ochki',
          },
          {
            title: 'Gloves',
            href: '/catalog/zhenskoe/aksessuary/perchatki',
          },
          {
            title: 'Headbands',
            href: '/catalog/zhenskoe/aksessuary/povyazki-na-golovu',
          },
        ],
      },
      {
        href: '/catalog/zhenskoe/aksessuary',
        items: [
          {
            title: 'Belts',
            href: '/catalog/zhenskoe/aksessuary/remni',
          },
          {
            title: 'Scarves',
            href: '/catalog/zhenskoe/aksessuary/sharfy-i-platki',
          },
        ],
      },
    ],
  },
  {
    title: 'Beauty',
    href: '/catalog/zhenskoe/beauty',
    items: [
      {
        title: 'Beauty',
        href: '/catalog/zhenskoe/beauty',
        items: [
          {
            title: 'Gadgets',
            href: '/catalog/zhenskoe/beauty/devaysy',
          },
          {
            title: 'Makeup',
            href: '/catalog/zhenskoe/beauty/makiyazh',
          },
          {
            title: 'Fragrance',
            href: '/catalog/zhenskoe/beauty/parfyumeriya',
          },
          {
            title: 'Hair Care',
            href: '/catalog/zhenskoe/beauty/ukhod-za-volosami',
          },
          {
            title: 'Skinncare',
            href: '/catalog/zhenskoe/beauty/ukhod-za-litsom',
          },
          {
            title: 'Body Care',
            href: '/catalog/zhenskoe/beauty/ukhod-za-telom',
          },
        ],
      },
    ],
  },
  {
    title: 'Shoes',
    href: '/catalog/zhenskoe/obuv',
    items: [
      {
        title: 'Shoes',
        items: [
          {
            title: 'Flip Flops',
            href: '/catalog/zhenskoe/obuv/baletki',
          },
          {
            title: 'Sandals',
            href: '/catalog/zhenskoe/obuv/bosonozhki',
          },
          {
            title: 'Ankle Boots',
            href: '/catalog/zhenskoe/obuv/botilony/botilony-i-polusapogi',
          },
          {
            title: 'Boots',
            href: '/catalog/zhenskoe/obuv/botinki',
          },
          {
            title: 'Brogues and Oxfords',
            href: '/catalog/zhenskoe/obuv/broggi-i-oksfordy',
          },
          {
            title: 'Sneakers',
            href: '/catalog/zhenskoe/obuv/krossovki-i-kedy',
          },
          {
            title: 'Low-heeled pumps',
            href: '/catalog/zhenskoe/obuv/lodochki-na-nizkom-kabluke',
          },
          {
            title: 'Loafers and Moccasins',
            href: '/catalog/zhenskoe/obuv/lofery-i-mokasiny',
          },
        ],
      },
      {
        href: '/catalog/zhenskoe/obuv',
        items: [
          {
            title: 'Mules',
            href: '/catalog/zhenskoe/obuv/myuli-i-sabo',
          },
          {
            title: 'Sandals and Flip flops',
            href: '/catalog/zhenskoe/obuv/sandalii-i-shlepancy',
          },
          {
            title: 'Boots and Thigh-High Boots',
            href: '/catalog/zhenskoe/obuv/sapogi-i-botforty',
          },
          {
            title: 'Wedding Shoes',
            href: '/catalog/zhenskoe/obuv/svadebnye-tufli',
          },
          {
            title: 'Pumps',
            href: '/catalog/zhenskoe/obuv/tufli',
          },
          {
            title: 'Espadrilles',
            href: '/catalog/zhenskoe/obuv/espadrili',
          },
        ],
      },
    ],
  },
  {
    title: 'Clothes',
    href: '/catalog/zhenskoe/odezhda',
    items: [
      {
        title: 'Clothes',
        items: [
          {
            title: 'Pants',
            href: '/catalog/zhenskoe/odezhda/bryuki',
          },
          {
            title: 'Outerwear',
            href: '/catalog/zhenskoe/odezhda/verhnyaya-odezhda',
          },
          {
            title: 'Knitwear',
            href: '/catalog/zhenskoe/odezhda/dzhempery-kardigany-trikotazh',
          },
          {
            title: 'Jeans',
            href: '/catalog/zhenskoe/odezhda/dzhinsy',
          },
          {
            title: 'Blazers and Jackets',
            href: '/catalog/zhenskoe/odezhda/zhakety-i-pidzhaki',
          },
          {
            title: 'Capris',
            href: '/catalog/zhenskoe/odezhda/kapri',
          },
          {
            title: 'Jumpsuits',
            href: '/catalog/zhenskoe/odezhda/kombinezony',
          },
        ],
      },
      {
        items: [
          {
            title: 'Corsets',
            href: '/catalog/zhenskoe/odezhda/korsety',
          },
          {
            title: 'Suits',
            href: '/catalog/zhenskoe/odezhda/kostyumy',
          },
          {
            title: 'Tops',
            href: '/catalog/zhenskoe/odezhda/majki-i-futbolki',
          },
          {
            title: 'Linergie and Homewear',
            href: '/catalog/zhenskoe/odezhda/nizhnee-bele-i-domashnyaya-odezhda',
          },
          {
            title: 'Dresses',
            href: '/catalog/zhenskoe/odezhda/platya',
          },
          {
            title: 'Beachwear',
            href: '/catalog/zhenskoe/odezhda/plyazhnaya-odezhda',
          },
          {
            title: 'Shirts and  Blouses',
            href: '/catalog/zhenskoe/odezhda/rubashki-i-bluzki',
          },
        ],
      },
      {
        href: '/catalog/zhenskoe/odezhda',
        items: [
          {
            title: 'Sportswear',
            href: '/catalog/zhenskoe/odezhda/sportivnaya-odezhda',
          },
          {
            title: 'Hoodies and Sweatshirts',
            href: '/catalog/zhenskoe/odezhda/hudi-i-tolstovki',
          },
          {
            title: 'Shorts',
            href: '/catalog/zhenskoe/odezhda/shorty',
          },
          {
            title: 'Skirts',
            href: '/catalog/zhenskoe/odezhda/yubki',
          },
        ],
      },
    ],
  },
  {
    title: 'Bags',
    href: '/catalog/zhenskoe/sumki',
    items: [
      {
        title: 'Bags',
        items: [
          {
            title: 'Bag Accessories',
            href: '/catalog/zhenskoe/sumki/aksessuary-dlya-sumok',
          },
          {
            title: 'Travel and Sports bags',
            href: '/catalog/zhenskoe/sumki/dorozhnye-i-sportivnye-sumki',
          },
          {
            title: 'Clutches and Pouches',
            href: '/catalog/zhenskoe/sumki/klatchi-i-vechernie-sumki',
          },
          {
            title: 'Beach Bags',
            href: '/catalog/zhenskoe/sumki/plyazhnye-sumki',
          },
          {
            title: 'Belt Bags',
            href: '/catalog/zhenskoe/sumki/poyasnye-sumki',
          },
          {
            title: 'Backpacks',
            href: '/catalog/zhenskoe/sumki/ryukzaki',
          },
          {
            title: 'Short handle Bags',
            href: '/catalog/zhenskoe/sumki/sumki-s-korotkimi-ruchkami',
          },
        ],
      },
      {
        href: '/catalog/zhenskoe/sumki',
        items: [
          {
            title: 'Totes',
            href: '/catalog/zhenskoe/sumki/sumki-tout',
          },
          {
            title: 'Crossbody Bags',
            href: '/catalog/zhenskoe/sumki/sumki-cherez-plecho',
          },
        ],
      },
    ],
  },
  {
    title: 'Jewelry',
    href: '/catalog/zhenskoe/ukrasheniya',
    items: [
      {
        title: 'Jewelry',
        href: '/catalog/zhenskoe/ukrasheniya',
        items: [
          {
            title: 'Pins and Brooches',
            href: '/catalog/zhenskoe/aksessuary/ukrasheniya/bulavki-i-broshi',
          },
          {
            title: 'Other',
            href: '/catalog/zhenskoe/aksessuary/ukrasheniya/drugoe',
          },
          {
            title: 'Cufflinks',
            href: '/catalog/zhenskoe/ukrasheniya/zaponki',
          },
          {
            title: 'Clips',
            href: '/catalog/zhenskoe/ukrasheniya/klipsy',
          },
          {
            title: 'Rings',
            href: '/catalog/zhenskoe/aksessuary/ukrasheniya/kolca',
          },
          {
            title: 'Sets',
            href: '/catalog/zhenskoe/ukrasheniya/komplekty',
          },
          {
            title: 'Chains and Pendants',
            href: '/catalog/zhenskoe/ukrasheniya/tsepochki-i-podveski',
          },
          {
            title: 'Watch',
            href: '/catalog/zhenskoe/ukrasheniya/chasy',
          },
        ],
      },
    ],
  },
  {
    title: 'High Jewelry',
    href: '/catalog/zhenskoe/yuvelirnye-izdeliya',
    items: [
      {
        title: 'High Jewelry',
        href: '/catalog/zhenskoe/yuvelirnye-izdeliya',
        items: [
          {
            title: 'Bracelets',
            href: '/catalog/zhenskoe/yuvelirnye-izdeliya/bracelety',
          },
          {
            title: 'Brooches',
            href: '/catalog/zhenskoe/yuvelirnye-izdeliya/broshi',
          },
          {
            title: 'Cufflinks',
            href: '/catalog/zhenskoe/yuvelirnye-izdeliya/zaponki',
          },
          {
            title: 'Rings',
            href: '/catalog/zhenskoe/yuvelirnye-izdeliya/colca',
          },
          {
            title: 'Sets',
            href: '/catalog/zhenskoe/yuvelirnye-izdeliya/komplekty',
          },
          {
            title: 'Earrings',
            href: '/catalog/zhenskoe/yuvelirnye-izdeliya/sergi',
          },
          {
            title: 'Chains and Pendants',
            href: '/catalog/zhenskoe/yuvelirnye-izdeliya/tsepochki-i-podveski',
          },
          {
            title: 'Watch',
            href: '/catalog/zhenskoe/yuvelirnye-izdeliya/tchasy',
          },
        ],
      },
    ],
  },
];
